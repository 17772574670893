import { type ReactNode, useMemo } from 'react';
import { noop } from 'lodash';

import { ChatContext } from '@src/contexts/chat-provider/chat-context';
import { useMobileWebview } from '@src/hooks/use-mobile-webview';

export const WebViewChatProvider = ({ children }: { children: ReactNode }) => {
  const { postMessage } = useMobileWebview();

  const value = useMemo(
    () => ({
      unreadCount: 0,
      open: () => postMessage({ action: 'OPEN_CHAT' }),
      close: noop,
    }),
    [postMessage]
  );

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};
