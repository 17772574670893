import {
  addDays,
  addYears,
  differenceInDays,
  parseISO,
  startOfDay,
} from 'date-fns';

/**
 * Returns whether the given date is less than 30 days in the future.
 * @param {string | Date} expiresOn ISO formatted date string, or the date itself
 */
export const expiresSoon = (expiresOn?: string | Date | null) => {
  if (!expiresOn) return false;

  const expiresOnDate =
    typeof expiresOn === 'string' ? parseISO(expiresOn) : expiresOn;
  return (
    differenceInDays(addDays(startOfDay(new Date()), 30), expiresOnDate) > 0
  );
};

/**
 * Returns whether the given date is in the past
 * @param {string | Date} expiresOn ISO formatted date string, or the date itself
 */
export const expired = (expiresOn?: string | Date | null) => {
  if (!expiresOn) return false;

  const expiresDate =
    typeof expiresOn === 'string' ? parseISO(expiresOn) : expiresOn;
  return differenceInDays(expiresDate, startOfDay(new Date())) <= 0;
};

export interface ItemExpiresOnParams {
  // ISO formatted date string, when the item expires, or the date itself
  expiresOn?: string | Date | null;
  // ISO formatted date string, when the item was completed
  completedOn?: string | Date | null;
  // Number of days the item is valid for
  validFor?: number;
  // If validFor is not provided, an object with a validFor parameter instead.
  healthDoc?: { validFor?: number };
}

/**
 * Returns the date that an item expires on, or null if it cannot be determined
 */
export const itemExpiresOn = ({
  expiresOn,
  completedOn,
  validFor,
  healthDoc,
}: ItemExpiresOnParams) => {
  if (expiresOn) return expiresOn;

  const completedOnDate =
    typeof completedOn === 'string' ? parseISO(completedOn) : completedOn;

  if (!!healthDoc || !!validFor) {
    const validForDays = validFor || (healthDoc && healthDoc.validFor);
    return completedOnDate && validForDays
      ? addDays(completedOnDate, validForDays)
      : null;
  }

  return completedOnDate ? addYears(completedOnDate, 1) : null;
};

export const isEmptyOrWhiteSpace = (str?: string | null) =>
  str == null ? true : str.trim().length === 0;

export const PHONE_NUMBER_REGEX = /^\d{3}-\d{3}-\d{4}$/;

export const SSN_REGEX = /^[\d*]{3}-[\d*]{2}-\d{4}$/;

export const ONLY_TEXT_REGEX = /^[\p{L}\s]+$/u;

export const NAME_SURNAME_REGEX = /^\p{L}+(?:[\s'-]\p{L}+)+$/u;
