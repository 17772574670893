import { MOBILE_MAX_WIDTH } from '@src/constants/breakpoints';

/**
 * SSR safe proxy for window.matchMedia
 */
export function matchMedia(query: string): MediaQueryList | undefined {
  if (typeof window !== 'undefined') return window.matchMedia(query);

  return undefined;
}

/**
 * Util method for seeing if the window width is currently below the MOBILE_MAX_WIDTH threshhold.
 *
 * If you are using this util in the body a component you should probably use useIsMobile hook instead so that the value is responsive
 */
export function isMobile() {
  const isMobile = matchMedia(`(max-width: ${MOBILE_MAX_WIDTH})`)?.matches;
  return !!isMobile;
}
