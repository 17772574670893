import {
  black,
  semanticErrorHigher,
  semanticErrorLow,
} from '@src/support/colors';
import { DESKTOP_MIN_WIDTH } from '@src/constants';
import { rgba } from '@src/support/rgba';

type Props = {
  body: string;
};

export function SystemMessage({ body }: Props) {
  return (
    <div className="SystemMessage">
      <p
        className="SystemMessage--body"
        dangerouslySetInnerHTML={{ __html: body }}
      />
      <style jsx>{`
        .SystemMessage {
          background-color: ${semanticErrorLow};
          box-shadow: ${rgba(black, 0.15)} 0 0 0.1875rem 0;
          padding: 0.9375rem;
          position: sticky;
          text-align: center;
          left: 0;
          right: 0;
          top: 59px;
          z-index: 100;
        }

        @media screen and (min-width: ${DESKTOP_MIN_WIDTH}) {
          .SystemMessage {
            padding: 1.25rem;
          }
        }

        .SystemMessage--body {
          color: ${semanticErrorHigher};
          font-weight: 400;
          font-size: 0.8125rem;
        }

        :global(.SystemMessage--body > strong) {
          color: ${semanticErrorHigher};
          font-weight: 700;
        }
      `}</style>
    </div>
  );
}
