import { useIsMobile } from '@src/hooks/use-is-mobile';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { trackNavigationClick } from '@src/utils/segment-events';

export const useTrackNavigationClick = () => {
  const isMobile = useIsMobile();
  const router = useRouter();

  return useCallback(
    (event: string, menu: string = 'side') => {
      trackNavigationClick(event, isMobile, router.asPath, { menu: menu });
    },
    [isMobile, router.asPath]
  );
};
