import type { MouseEventHandler, PropsWithChildren } from 'react';
import Link from 'next/link';

import type { RequireOneOf } from '@src/utils/types';

interface OwnProps {
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  href?: string;
  newTab?: boolean;
}

type Props = PropsWithChildren<RequireOneOf<OwnProps, 'href', 'onClick'>>;

export const NavigationDropdownLink = ({
  href = '',
  onClick,
  newTab = false,
  children,
}: Props) => {
  const isExternalLink = () => {
    try {
      const url = new URL(href);
      return url.host !== window.location.host;
    } catch (error) {
      // Default is that it is not an external link
      return false;
    }
  };

  return (
    <>
      <div className="NavigationDropdownLink">
        <Link href={href} passHref legacyBehavior>
          <a
            onClick={onClick}
            rel={newTab || isExternalLink() ? 'noopener noreferrer' : ''}
            target={newTab || isExternalLink() ? '_blank' : ''}
          >
            {children}
          </a>
        </Link>
        <style jsx>{`
          .NavigationDropdownLink {
            font-size: 1rem;
          }

          :global(.NavigationDropdownLink + .NavigationDropdownLink) {
            padding-top: 0.625em;
          }
        `}</style>
      </div>
    </>
  );
};
