import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { analytics } from '@src/lib/analytics';

const trackPage = () => analytics.page();

// https://github.com/vercel/next.js/tree/canary/examples/with-segment-analytics-pages-router
export const AnalyticsRoutes = () => {
  const router = useRouter();

  useEffect(() => {
    trackPage();
  }, []);

  useEffect(() => {
    router.events.on('routeChangeComplete', trackPage);

    return () => {
      router.events.off('routeChangeComplete', trackPage);
    };
  }, [router.events]);

  return null;
};
