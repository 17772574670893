// ============================================================================
// COLOR DECLARATIONS
// Do not add any new colors unless strictly necessary, talk to your designer
// to make sure we shouldn't be using the colors from the color palette on Figma:
// https://www.figma.com/design/A5MIQ4LElRMzA0sL8W6CNp/MPDS---Foundations?node-id=5656-41668&t=2gobhfBCClJvLpER-4
// ============================================================================
export const black = '#000000';
export const white = '#FFFFFF';
export const primary01 = '#49E1BB';
export const primary02 = '#D7128A';
export const primary03 = '#F9CB32';
export const primary04 = '#0F1041';
export const accent01Lowest = '#E7F1EF'; // -> Accent 01 Lower 1
export const accent01Lower = '#CDF4EA';
export const accent01Low = '#A1E5D4';
export const accent01 = '#31FBC8';
export const accent01High = '#48C7A7';
export const accent02Lower = '#FFF5FB';
export const accent02Low = '#FAE3F1';
export const accent02 = '#FFC5E8';
export const accent02High = '#B54188';
export const accent03Lower = '#FAF6E8';
export const accent03Low = '#FFF4CF';
export const accent03 = '#FFE799';
export const accent03High = '#D9A600';
export const neutralLower = '#F9F9F9';
export const neutralLow = '#EDEDED';
export const neutralDefault = '#E4E2DE';
export const neutralHigh = '#B2B1B6';
export const neutralHigher = '#818181';
export const neutralHighest = '#5E5C5C';
export const neutralHighest1 = '#323135';

// ==================
// SEMANTIC
// ==================

// Success
export const semanticSuccessLower = '#EEFAEF';
export const semanticSuccessLow = '#DAF5DC';
export const semanticSuccess = '#4CAF50';
export const semanticSuccessHigh = '#1B5E20';

// Info
export const semanticInfoLower = '#F0F6FA';
export const semanticInfoLow = '#D9ECFA';
export const semanticInfo = '#339AF0';
export const semanticInfoHigh = '#1864AB';

// Error
export const semanticErrorLower = '#FFF5F5';
export const semanticErrorLow = '#FFDEDE';
export const semanticError = '#FF6B6B';
export const semanticErrorHigh = '#C92A2A';
export const semanticErrorHigher = '#992020';

// Warning
export const semanticWarningLower = '#FFF8F0';
export const semanticWarningLow = '#FFEBD1';
export const semanticWarning = '#FF922B';
export const semanticWarningHigh = '#A04D00';

// Social media colors - not on Figma
export const colorEmail = '#1D8292';
export const colorFB = '#3D599A';
export const colorFBMessenger = '#007FFF';
export const colorFacebook = '#4B69AC';
export const colorApple = '#000';
export const colorLinkedIn = '#2F6FB9';
export const colorSMS = '#778C94';
export const colorTwitter = '#48A6E0';
export const colorWA = '#5BAB3D';
export const colorYahoo = '#430297';
