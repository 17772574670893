import { createContext, useContext, useState, type ReactNode } from 'react';

const nothing = () => {};

const WarningContext = createContext<{
  warning: { [id: string]: string };
  setWarning: (id: string, message: string) => void;
}>({ warning: {}, setWarning: nothing });

export const useWarning = () => {
  const context = useContext(WarningContext);

  return {
    setWarning: (id: string, message: string) => {
      context.setWarning(id, message);
    },
    getWarning: (id: string) => {
      return context.warning[id];
    },
  };
};

export const WarningProvider = ({ children }: { children: ReactNode }) => {
  const [warning, setWarning] = useState<{ [id: string]: string }>({});

  const setWarningById = (id: string, message: string) => {
    setWarning(prevWarnings => ({ ...prevWarnings, [id]: message }));
  };

  return (
    <WarningContext.Provider
      value={{
        warning,
        setWarning: setWarningById,
      }}
    >
      {children}
    </WarningContext.Provider>
  );
};
