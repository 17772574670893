import {
  PHONE_NUMBER_REGEX,
  SSN_REGEX,
  ONLY_TEXT_REGEX,
} from '@src/support/general';
import * as Yup from 'yup';
import { addYears, subYears, startOfTomorrow } from 'date-fns';

// Common date-related Yup validations

export function validDate() {
  return Yup.date()
    .min(subYears(startOfTomorrow(), 100), '${path} is too old')
    .max(addYears(startOfTomorrow(), 100), '${path} is too futuristic')
    .nullable()
    .typeError('${path} must be a valid date');
}

export function futureDateIgnoreToday() {
  return validDate().min(
    new Date(startOfTomorrow()),
    '${label} must be a future date'
  );
}

export function futureDate() {
  return validDate().min(
    new Date(new Date().setHours(0, 0, 0, 0)),
    // FIXME: our pattern should be always label, we need to add a label for all
    //  fields that use this and other validations in this file
    ({ label, path }) => `${label || path} must be a future date`
  );
}

export function pastDateLessThanOneYearAgo() {
  return pastDate().min(
    new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
    '${path} must be no earlier than a year ago'
  );
}

export function pastDate() {
  return validDate().max(
    new Date(new Date().setHours(24, 0, 0, 0)),
    '${path} must be a past date'
  );
}

// Validations for specific commonly-used fields to ensure consistent labeling
const yupString = (label: string) => Yup.string().label(label);

export const Validators = {
  // Account-related
  password: (label = 'Password') => yupString(label).min(6),
  passwordConfirmation: (
    label = 'Password confirmation',
    passwordField = 'password'
  ) =>
    yupString(label).when(passwordField, {
      is: (password: string) => !!password,
      then: schema =>
        schema
          .oneOf([Yup.ref(passwordField)], 'Passwords must match')
          .required('Please verify your new password'),
    }),

  // Personal details
  // First, preferred, and last name character limits are from database columns
  firstName: (label = 'First name') =>
    yupString(label)
      .matches(ONLY_TEXT_REGEX, '${label} must be only text')
      .max(40),
  middleName: (label = 'Middle name') =>
    yupString(label).matches(ONLY_TEXT_REGEX, '${label} must be only text'),
  lastName: (label = 'Last name') =>
    yupString(label)
      .matches(ONLY_TEXT_REGEX, '${label} must be only text')
      .max(80),
  preferredName: (label = 'Preferred name') => yupString(label).max(100),
  pronouns: (label = 'Preferred pronoun') =>
    yupString(label).max(30, '${path} must be 30 characters or less'),
  birthday: (label = 'Date of birth') => pastDate().label(label),
  ssn: (label = 'Social security number') =>
    yupString(label)
      .min(11)
      .matches(SSN_REGEX, '${path} must be formatted XXX-XX-XXXX'),

  // Contact info
  email: (label = 'Email') => yupString(label).email().max(80),
  phoneNumber: (label = 'Phone number') =>
    yupString(label)
      .min(12)
      .max(30)
      .test(
        'no-start-with-1',
        '${path} must not start with 1',
        phone => !(phone && phone[0] === '1')
      )
      .test(
        'no-start-with-0',
        '${path} must not start with 0',
        phone => !(phone && phone[0] === '0')
      )
      .matches(PHONE_NUMBER_REGEX, '${path} is not valid'),

  // Locations
  city: (label = 'City') => yupString(label),
  state: (label = 'State') => yupString(label),
  streetAddress: (label = 'Street address') => yupString(label),
  zipcode: (label = 'Zipcode') => yupString(label).max(20),
};
