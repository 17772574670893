import { useFormikContext, getIn } from '@src/ui/formik';

/**
 * When defining form sections that may or may not be prefixed,
 * this method can be used to get the current values of only the current form
 * section.
 * @example
 * // In this example, we have a main form with values
 * // mainform.tsx
 * interface SomeFormValues {
 *   firstName: string;
 *   favorites: FavoritesSubformValues;
 * }
 * export function SomeForm() {
 *   return (
 *     <FormikTextField name="firstName" />
 *     <FavoritesSubform prefix="favorites" />
 *   );
 * }
 * // And a separately defined section,
 * // favorites-subform.tsx
 * interface FavoritesSubformValues {
 *   dogBreed: string;
 * }
 *
 * export function FavoritesSubform({ prefix }: Props) {
 *   const values = useNestedFormikValues<T>(prefix);
 *   console.log(values.dogBreed);
 *   return <FormikTextField name={prefixedFieldName('dogBreed', prefix)} />;
 * }
 */
export function useNestedFormikValues<T>(prefix?: string) {
  const { values } = useFormikContext();
  return (prefix ? getIn(values, prefix) : values) as T;
}

export function useNestedFormik<T>(prefix?: string) {
  const { values, ...rest } = useFormikContext();
  return { values: (prefix ? getIn(values, prefix) : values) as T, ...rest };
}

export function prefixedFieldName(name: string, prefix?: string) {
  return prefix ? `${prefix}.${name}` : name;
}
