import type { ComponentProps, FormEvent, PropsWithChildren } from 'react';
import { Field, type FieldProps, type FieldValidator } from '@src/ui/formik';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  RadioGroup,
} from '@src/ui/material-ui';
import { getHelperText, hasError } from './utils';
import type { ClassValue } from 'clsx';
import clsx from 'clsx';
import * as colors from '@src/support/colors';

type Props = {
  castValue?: 'boolean';
  disabled?: boolean;
  FormControlProps?: ComponentProps<typeof FormControl<'fieldset'>>;
  FormLabelProps?: ComponentProps<typeof FormLabel<'legend'>>;
  fullWidth?: boolean;
  helperText?: string;
  description?: string;
  innerRef?: unknown;
  label?: string;
  margin?: 'none' | 'dense' | 'normal';
  name: string;
  RadioGroupProps?: ComponentProps<typeof RadioGroup>;
  required?: boolean;
  row?: boolean;
  validate?: FieldValidator;
  variant?: 'standard' | 'outlined' | 'filled';
  className?: ClassValue;
  hideErrors?: boolean;
};

export function FormikRadioField({
  castValue,
  children,
  disabled = false,
  FormControlProps,
  FormLabelProps,
  fullWidth = false,
  helperText,
  description,
  innerRef,
  label,
  margin = 'none',
  name,
  RadioGroupProps,
  required = false,
  row = false,
  validate,
  variant = 'standard',
  className,
  hideErrors = false,
}: PropsWithChildren<Props>) {
  return (
    <>
      <Field name={name} innerRef={innerRef} validate={validate}>
        {({ field, form }: FieldProps) => {
          const onChange =
            castValue === 'boolean'
              ? (event: FormEvent<HTMLInputElement>, value: string) => {
                  event.preventDefault();
                  form.setFieldValue(field.name, value === 'true');
                }
              : field.onChange;
          const value =
            typeof field.value === 'boolean'
              ? field.value.toString()
              : field.value;
          return (
            <FormControl
              component="fieldset"
              disabled={disabled}
              fullWidth={fullWidth}
              margin={margin}
              variant={variant}
              error={hasError(field.name, form.touched, form.errors)}
              {...FormControlProps}
            >
              <FormLabel
                className={clsx(className)}
                {...Object.assign({ shrink: 'true' }, FormLabelProps)}
                component="legend"
              >
                {label}
                {required && (
                  <span className="FormikRadioField--required"> *</span>
                )}
              </FormLabel>

              {description && <FormHelperText>{description}</FormHelperText>}

              <RadioGroup
                name={field.name}
                onChange={onChange}
                row={row}
                value={value}
                {...RadioGroupProps}
              >
                {children}
              </RadioGroup>

              {!hideErrors &&
              (hasError(field.name, form.touched, form.errors) ||
                helperText) ? (
                <FormHelperText>
                  {getHelperText(
                    field.name,
                    form.touched,
                    form.errors,
                    helperText
                  )}
                </FormHelperText>
              ) : null}
            </FormControl>
          );
        }}
      </Field>
      <style jsx>{`
        .FormikRadioField--required {
          color: ${colors.semanticError};
        }
      `}</style>
    </>
  );
}
