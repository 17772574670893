import Link from 'next/link';
import { useRouter } from 'next/router';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';

import { NavigationTabs, NavigationTabsItem } from '@src/components/navigation';
import { Badge, FontAwesomeIcon } from '@src/ui';
import { nonNullValues } from '@src/utils/types';
import { analytics } from '@src/lib/analytics';
import { SEGMENT_EVENTS } from '@src/constants';

export type TopAppBarBottomTabProps = {
  tabs?: ReadonlyArray<{
    href: string;
    label: string;
    icon?: IconProp;
    badge?: boolean;
  } | null>;
  source?: string;
};

export function TopAppBarBottomTabs({ tabs, source }: TopAppBarBottomTabProps) {
  const router = useRouter();

  if (!tabs) {
    return;
  }

  return (
    <>
      <NavigationTabs>
        {tabs.filter(nonNullValues).map(tab => (
          <NavigationTabsItem
            key={tab.label}
            selected={
              router.pathname === tab.href || router.asPath === tab.href
            }
          >
            <Link
              href={tab.href}
              onClick={e => {
                e.preventDefault();

                analytics.track(
                  SEGMENT_EVENTS.NAVIGATION.CLICKED_TOP_MENU_ITEM,
                  {
                    source: source,
                    item: tab.label,
                  }
                );

                router.push(tab.href);
              }}
            >
              {!!tab.icon && (
                <div className="Icon">
                  <FontAwesomeIcon icon={tab.icon} />
                </div>
              )}
              {tab.label}
              {tab.badge && (
                <Badge color="secondary">
                  <div className="BadgeChild" />
                </Badge>
              )}
            </Link>
          </NavigationTabsItem>
        ))}
      </NavigationTabs>
      <style jsx>{`
        .Icon {
          margin-right: 0.25rem;
        }

        .BadgeChild {
          height: 1rem;
        }
      `}</style>
    </>
  );
}
