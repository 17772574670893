import type { PropsWithChildren } from 'react';

import * as colors from '@src/support/colors';
import clsx from 'clsx';

type Props = {
  selected?: boolean;
};

export const NavMainHeaderTabsListItem = (props: PropsWithChildren<Props>) => (
  <li
    className={clsx('NavMainHeaderTabsListItem', {
      'NavMainHeaderTabsListItem--unselected': !props.selected,
      'NavMainHeaderTabsListItem--selected': props.selected,
    })}
  >
    {props.children}
    <style jsx>{`
      .NavMainHeaderTabsListItem {
        align-items: center;
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;
        font-size: 0.875rem;
        justify-content: center;
        min-height: 3.5rem;
        position: relative;
      }

      .NavMainHeaderTabsListItem > :global(a) {
        align-items: center;
        bottom: 0;
        color: inherit;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
      }

      .NavMainHeaderTabsListItem > :global(a::after) {
        background-color: transparent;
        bottom: 0;
        content: '';
        display: block;
        height: 0.25rem;
        left: 0;
        position: absolute;
        right: 0;
        width: 100%;
      }

      .NavMainHeaderTabsListItem > :global(a:hover::after) {
        background-color: ${colors.neutralDefault};
        color: ${colors.primary01};
      }

      .NavMainHeaderTabsListItem--selected > :global(a::after),
      .NavMainHeaderTabsListItem--selected > :global(a:hover::after) {
        background-color: ${colors.primary01};
      }

      .NavMainHeaderTabsListItem--unselected {
        font-weight: 300;
        color: ${colors.neutralHighest};
      }

      .NavMainHeaderTabsListItem--selected {
        font-weight: 600;
        color: ${colors.neutralHighest1};
      }
    `}</style>
  </li>
);
