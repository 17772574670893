import type { PropsWithChildren } from 'react';
import { NavMainHeaderTabs, NavMainHeaderTabsList } from '@src/ui';

type Props = PropsWithChildren<{
  withinPanel?: boolean;
}>;

export function NavigationTabs({ children, withinPanel = false }: Props) {
  return (
    <NavMainHeaderTabs withinPanel={withinPanel}>
      <NavMainHeaderTabsList withinPanel={withinPanel}>
        {children}
      </NavMainHeaderTabsList>
    </NavMainHeaderTabs>
  );
}
