import { createPortal } from 'react-dom';
import {
  type PropsWithChildren,
  useState,
  useEffect,
  useLayoutEffect,
} from 'react';

const useIsomorphicEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export function Portal(props: PropsWithChildren<{}>) {
  const [container, setContainer] = useState<HTMLElement>();

  useIsomorphicEffect(() => {
    setContainer(document.body);
  }, []);
  return container
    ? createPortal(props.children, container, 'Portal')
    : container || null;
}
