import { useMemo } from 'react';

declare global {
  interface Window {
    ReactNativeWebView?: {
      postMessage(message: string): void;
      devMagicAllowed?: boolean;
    };
  }
}

type MobileCommand =
  | {
      action:
        | 'LOGOUT'
        | 'OPEN_ACCOUNT'
        | 'OPEN_CHAT'
        | 'OPEN_NOTIFICATIONS'
        | 'OPEN_NOTIFICATION_SETTINGS'
        | 'OPEN_SETTINGS'
        | 'OPEN_SELECT_HELP_TOPIC'
        | 'OPEN_DEV_MENU';
    }
  | {
      action: 'OPEN_MY_TIME';
      payload: { jobPlacementScheduleId: string };
    };

const postMessage = (message: MobileCommand) => {
  window?.ReactNativeWebView?.postMessage(JSON.stringify(message));
};

export const useMobileWebview = () => {
  return useMemo(
    () => ({
      isPresent: !!window.ReactNativeWebView,
      isDevMagicAllowed: !!window.ReactNativeWebView?.devMagicAllowed,
      postMessage,
    }),
    []
  );
};
