import type { Opaque } from 'ts-essentials';

export const GQL_DATE_FORMAT = 'yyyy-MM-dd';
export const GQL_DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ssxxx";

/**
 * A date string directly from GraphQL. Convert with the utilities in @src/lib/date-io before using.
 */
export type GraphQLISO8601Date = Opaque<string, 'ISO8601Date'>;

/**
 * A datetime string directly from GraphQL. Convert with the utilities in @src/lib/date-io before using.
 */
export type GraphQLISO8601DateTime = Opaque<string, 'ISO8601DateTime'>;
