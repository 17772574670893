import {
  type PropsWithChildren,
  type RefObject,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { usePopper } from 'react-popper';

import { Portal } from '../portal';
import * as colors from '@src/support/colors';
import { rgba } from '@src/support/rgba';

type Props = {
  anchorRef?: RefObject<Element | undefined>;
  offsetX?: number;
  offsetY?: number;
  onClose?: () => void;
  open?: boolean;
  shape?: 'rounded' | 'rectangle';
  shadow?: 'default' | 'bottom';
  animate?: 'fade' | 'slideDown' | 'none';
  placement?: 'bottom-start' | 'bottom-end';
};

export function Popover({
  anchorRef,
  offsetX = 0,
  offsetY = 0,
  open = false,
  shape = 'rounded',
  shadow = 'default',
  animate = 'fade',
  placement = 'bottom-start',
  onClose,
  children,
}: PropsWithChildren<Props>) {
  const [popoverElement, setPopoverElement] = useState<HTMLElement | null>();
  const previousFocusRef = useRef<HTMLElement>();

  useEffect(() => {
    if (open && popoverElement) {
      previousFocusRef.current = document.activeElement as HTMLElement;
      if (!document.documentElement.classList.contains('mouse')) {
        popoverElement.focus();
      }
    } else if (previousFocusRef.current?.focus) {
      if (!document.documentElement.classList.contains('mouse')) {
        previousFocusRef.current.focus();
      }
    }
  }, [popoverElement, open]);

  const { attributes, styles, update } = usePopper(
    anchorRef?.current,
    popoverElement,
    {
      placement: placement,
      modifiers: [
        {
          name: 'preventOverflow',
          options: {
            rootBoundary: 'document',
          },
        },
        {
          name: 'offset',
          options: {
            offset: [offsetX, offsetY],
          },
        },
      ],
    }
  );

  // Due to the way we layout somethings we need to constantly keep checking
  // if an element was moved (this fixes issues with the popover
  // on profile page combined with our jumpy sidebar)
  useLayoutEffect(() => {
    if (!anchorRef) {
      return;
    }

    let ref: string | undefined;

    // Less accurate than an observer but good enough
    const interval = setInterval(() => {
      const newRef = JSON.stringify(
        anchorRef.current?.getBoundingClientRect().toJSON() || {}
      );

      if (ref !== newRef) {
        ref = newRef;
        update?.();
      }
    }, 300);

    return () => clearInterval(interval);
  }, [anchorRef, update]);

  return (
    <Portal>
      {open && <Overlay onClose={onClose} />}
      <div
        ref={setPopoverElement}
        className={clsx('PopoverContainer', {
          visible: open,
          'animate-fade': animate === 'fade',
          'animate-slide-down': animate === 'slideDown',
        })}
        tabIndex={-1}
        style={styles.popper}
        onKeyUp={event => {
          if (event.key === 'Escape') {
            onClose?.();
          }
        }}
        {...attributes.popper}
      >
        <div
          className={clsx('Popover', {
            'shape-rounded': shape === 'rounded',
            'shape-rectangle': shape === 'rectangle',
            'shadow-default': shadow === 'default',
            'shadow-bottom': shadow === 'bottom',
          })}
        >
          {children}
        </div>
        <style jsx>{`
          .PopoverContainer {
            outline: none;
          }

          .PopoverContainer,
          .PopoverContainer .Popover {
            visibility: hidden;
          }

          .PopoverContainer.visible,
          .PopoverContainer.visible .Popover {
            visibility: visible;
            opacity: 1;
          }

          .PopoverContainer .Popover {
            background-color: ${colors.white};
            overflow-x: hidden;
            overflow-y: auto;
            will-change: opacity, transform;
            opacity: 0;
            visibility: hidden;
          }

          .PopoverContainer.visible.animate-fade .Popover {
            transform: translateY(0%);
          }

          .PopoverContainer,
          .PopoverContainer.animate-fade .Popover {
            transition:
              opacity 200ms,
              visibility 200ms;
          }

          .PopoverContainer.animate-fade .Popover {
            transform: translateY(10%) scale(0.9);
          }

          .PopoverContainer.visible.animate-slide-down .Popover {
            transform: translateY(0%);
          }

          .PopoverContainer,
          .PopoverContainer.animate-slide-down .Popover {
            transition:
              opacity 300ms cubic-bezier(0.39, 0.58, 0.57, 1),
              transform 300ms cubic-bezier(0.39, 0.58, 0.57, 1),
              visibility 300ms;
          }

          .PopoverContainer.animate-slide-down .Popover {
            transform: translateY(-10%);
          }

          .PopoverContainer .Popover.shape-rounded {
            border-radius: 0.25em;
          }

          .PopoverContainer .Popover.shape-rectangle {
            border-radius: 0;
          }

          .PopoverContainer .Popover.shadow-default {
            box-shadow: 0 0 1.2307692307692308em ${rgba(colors.black, 0.16)};
          }

          .PopoverContainer .Popover.shadow-bottom {
            box-shadow: 0em 0.0625em 0.1875em ${rgba(colors.black, 0.16)};
          }
        `}</style>
      </div>
    </Portal>
  );
}

type OverlayProps = {
  onClose?: () => void;
};

function Overlay({ onClose }: OverlayProps) {
  return (
    <div className="Overlay" onClick={onClose}>
      <style jsx>{`
        .Overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
        }
      `}</style>
    </div>
  );
}
