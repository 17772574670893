declare global {
  interface Window {
    // https://developer.fullstory.com/browser/v1/get-session-details/
    FS?: {
      getCurrentSessionURL(now: boolean): string;
    };
  }
}

export function getFullStoryCurrentSessionURL(now: boolean) {
  if (typeof window === 'undefined' || !window.FS?.getCurrentSessionURL) {
    return null;
  }

  try {
    return window.FS.getCurrentSessionURL(now);
  } catch (error) {
    console.error(error);

    return null;
  }
}
