import type { PropsWithChildren } from 'react';
import { NavMainHeaderTabsListItem } from '@src/ui';

type Props = {
  selected?: boolean;
};

export function NavigationTabsItem({
  selected,
  children,
}: PropsWithChildren<Props>) {
  return (
    <NavMainHeaderTabsListItem selected={selected}>
      {children}
    </NavMainHeaderTabsListItem>
  );
}
