import type { Opaque } from 'ts-essentials';
import {
  addDays,
  format,
  isAfter,
  isBefore,
  isSameDay,
  isValid,
  parseISO,
} from 'date-fns';
import {
  GQL_DATE_FORMAT,
  GQL_DATE_TIME_FORMAT,
  type GraphQLISO8601Date,
  type GraphQLISO8601DateTime,
} from '@src/apollo/types';

// TypeScript allows passing our fake opaque types defined as `<Opaque<string, ...>>` as
// a `string` argument to methods, but we don't want to allow that in a lot of places.
// This type will allow only raw `string`s and not opaque types built on them.
// We can't really avoid this for `any` arguments though unfortunately.
type NotOpaque<T> = Partial<Opaque<T, 'NotOpaque'>>;

const isFutureDate = (date: Date | string | null) => {
  if (!isValid(date)) {
    throw new TypeError('isFutureDate must be given a valid date');
  }

  const now = new Date();
  return isAfter(date as Date, now) || isSameDay(date as Date, now);
};

const isPastDate = (date: Date | string | null) => {
  if (!isValid(date)) {
    throw new TypeError('isPastDate must be given a valid date');
  }

  const now = new Date();
  return isBefore(date as Date, now) || isSameDay(date as Date, now);
};

const isValidDateString = (dateString: string) => isValid(parseISO(dateString));

const parseDate = (dateString?: Date | string | null) => {
  if (!dateString) {
    return null;
  }

  if (dateString instanceof Date) {
    return dateString;
  }

  const date = parseISO(dateString);
  if (isValid(date)) {
    const timezoneOffset = date.getTimezoneOffset();
    return new Date(date.getTime() + timezoneOffset * 60 * 1000);
  }

  return new Date('');
};

const parseTime = (timeString: string) => {
  return parseISO(timeString);
};

const formatFromDateString = (
  dateString: string,
  formatString: string,
  fallback = ''
) => {
  const date = parseISO(dateString);
  if (!date) return null;

  return isValid(date) ? format(date, formatString) : fallback;
};

// Adjusts a date so conversion from TZ to UTC does not change the day
const adjustDateForUTC = (date: Date) => {
  if (!isValid(date)) {
    throw new TypeError('adjustDateForUTC must be given a valid date');
  }

  const dateAtMidnight = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  );

  // If the time is behind UTC then just return as is since
  // midnight in local time will always be same day in UTC
  // Else if it is ahead adjust the time forward so UTC doesn't
  // fall back to the previous day
  const timezoneOffset = date.getTimezoneOffset() * 60 * 1000;
  if (timezoneOffset >= 0) {
    return dateAtMidnight;
  } else {
    return new Date(dateAtMidnight.getTime() - timezoneOffset);
  }
};

const formatGQLDate = (date: GraphQLISO8601Date, formatString: string) => {
  return format(gqlDateToDate(date), formatString);
};

const toGQLDate = (date: string): GraphQLISO8601Date => {
  if (!isValidDateString(date)) {
    throw new RangeError(
      `Unexpected date format, expected ${GQL_DATE_FORMAT}: ${date}`
    );
  }
  return date as unknown as GraphQLISO8601Date;
};

const toGQLDateTime = (date: NotOpaque<string>): GraphQLISO8601DateTime => {
  const dateTimeRegex =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}([-+]\d{2}:\d{2}|Z)$/;
  const d = date as string;
  if (!d.match(dateTimeRegex)) {
    throw new RangeError(
      `Unexpected datetime format, expected ${GQL_DATE_TIME_FORMAT}: ${date}`
    );
  }
  return d as GraphQLISO8601DateTime;
};

/**
 * Parses an ISO8601Date from GraphQL into a Date
 */
export function gqlDateToDate(date?: null | undefined): null;
export function gqlDateToDate(date: GraphQLISO8601Date): Date;
export function gqlDateToDate(date?: GraphQLISO8601Date | null): Date | null;
export function gqlDateToDate(date?: GraphQLISO8601Date | null): Date | null {
  if (typeof date === 'undefined' || date === null) return null;
  const dateStr = date as unknown as string;
  if (!isValidDateString(dateStr)) {
    throw new RangeError(`Unexpected date format: ${dateStr}`);
  }

  const [yearStr, monthStr, dayStr] = dateStr.split('-');
  const outDate = new Date(
    parseInt(yearStr, 10),
    parseInt(monthStr, 10) - 1,
    parseInt(dayStr, 10),
    0,
    0,
    0,
    0
  );

  if (!isValid(outDate)) {
    throw new RangeError(`Unexpected date format: ${dateStr}`);
  }
  return adjustDateForUTC(outDate);
}

/**
 * Parses an ISO8601DateTime from GraphQL into a Date
 */
export function gqlDateTimeToDate(date?: null): null;
export function gqlDateTimeToDate(date: GraphQLISO8601DateTime): Date;
export function gqlDateTimeToDate(
  date?: GraphQLISO8601DateTime | null
): Date | null;
export function gqlDateTimeToDate(
  date?: GraphQLISO8601DateTime | null
): Date | null {
  if (typeof date === 'undefined' || date === null) return null;
  const dateStr = date as unknown as string;
  const outDate = parseISO(dateStr);

  if (!isValid(outDate)) {
    throw new RangeError(`Unexpected date format: ${date}`);
  }
  return outDate;
}

export function dateToGQLDate(date: Date): GraphQLISO8601Date {
  const adjustedDate = adjustDateForUTC(date);
  return format(adjustedDate, GQL_DATE_FORMAT) as GraphQLISO8601Date;
}

export function dateToGQLDateTime(date: Date): GraphQLISO8601DateTime {
  return format(date, GQL_DATE_TIME_FORMAT) as GraphQLISO8601DateTime;
}

export const dateUtils = {
  addDays,
  adjustDateForUTC,
  format,
  formatFromDateString,
  formatGQLDate,
  isBefore,
  isFutureDate,
  isPastDate,
  isSameDay,
  isValid,
  isValidDateString,
  parseDate,
  parseTime,
  toGQLDate,
  toGQLDateTime,
};
