import { NavigationDropdownLink } from '@src/components/navigation-profile-dropdown/navigation-dropdown-link';

import type { MouseEventHandler } from 'react';

type Props = {
  onClick: MouseEventHandler<HTMLAnchorElement>;
  href: string;
  label: string;
};

export const NavigationProfileDropdownLink = ({
  href,
  onClick,
  label,
}: Props) => {
  return (
    <>
      <div className="NavigationProfileDropdownLink">
        <NavigationDropdownLink href={href} onClick={onClick}>
          <span>{label}</span>
        </NavigationDropdownLink>
      </div>
      <style jsx>{`
        .NavigationProfileDropdownLink {
          margin-bottom: 1rem;
        }

        .NavigationProfileDropdownLink:last-child {
          margin-bottom: 0;
        }
      `}</style>
    </>
  );
};
