import * as Sentry from '@sentry/nextjs';

export function isFilteredRequestError(event: Sentry.ErrorEvent) {
  // In case there's a chain, we take the last entries
  const mainAndMaybeCauseErrors = event.exception?.values?.slice(-2) ?? [];

  // These usually come in high volume and are not actionable
  for (const error of mainAndMaybeCauseErrors) {
    const is404 =
      error.type === 'NotFoundError' &&
      !!error.value?.match(/(GET|POST|PUT|DELETE) .* 404/);
    const is429 =
      error.type === 'TooManyRequestsError' &&
      !!error.value?.match(/(GET|POST|PUT|DELETE) .* 429/);

    if (is404 || is429) {
      return true;
    }
  }

  return false;
}
