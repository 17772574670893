import { useRef } from 'react';
import { SnackbarMessenger } from './snackbar-messenger';

export function useSnackbarMessenger() {
  const snackbarMessengerRef = useRef<SnackbarMessenger | null>(null);

  if (!snackbarMessengerRef.current) {
    snackbarMessengerRef.current = new SnackbarMessenger();
  }
  return [snackbarMessengerRef.current];
}
