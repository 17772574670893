import type { PropsWithChildren } from 'react';

import * as Colors from '@src/support/colors';
import Link from 'next/link';

type Props = {
  href: string;
};

export const NavSidebarLogo = ({
  href,
  children,
}: PropsWithChildren<Props>) => (
  <div className="NavSidebarLogo">
    <Link href={href} className="NavLink">
      {children}
    </Link>
    <style jsx>
      {`
        .NavSidebarLogo :global(.NavLink) {
          -webkit-tap-highlight-color: transparent;
          display: flex;
        }

        .NavSidebarLogo {
          align-self: center;
          display: flex;
          justify-content: center;
          background-color: ${Colors.white};
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      `}
    </style>
  </div>
);
