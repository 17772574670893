import type { UserFullNameFragment } from '@generated/graphql';
import { capitalize } from 'lodash';

export type SignupResultUserData = {
  uuid: string;
  email: string;
  state?: string;
  lastName: string;
  firstName: string;
  authToken: string;
  preferredName: string;
  intercomUserHash: string | undefined;
  gclid?: string | null;
  signupSource: string | undefined;
  signupSourceQualifier: string | undefined;
  createdAt: string | undefined;
};

interface DisplayNameOptions {
  // Whether to include the user's first name
  first?: boolean;
  // Whether to include the user's preferred name
  preferred?: boolean;
  // Whether to include the user's middle name
  middle?: boolean;
  // Whether to include the user's last name
  last?: boolean;
  // Whether to include the user's first name regardless of preferred name presence
  forceFirst?: boolean;
  // Whether to try to automatically capitalize the user's first name
  capitalizeFirst?: boolean;
  // Whether to shorten the user's last name to one character and a dot, eg "Anderson" -> "A."
  abbreviateLast?: boolean;
}

/**
 * Returns the name that should be displayed for a given user, taking into account
 * the desired output and the user's preferred name.
 */
export function userDisplayName(
  user: UserFullNameFragment,
  {
    first = true,
    preferred = true,
    middle = false,
    last = true,
    forceFirst = false,
    capitalizeFirst = false,
    abbreviateLast = false,
  }: DisplayNameOptions = {}
) {
  const parts = [];
  // Because some names have capital letters within them, we don't want to automatically
  // change the capitalization on all names. Let's just do it for names that don't appear to have been
  // intentionally capitalized at all, which are the only cases we can probably catch programatically here.
  const conditionallyCapitalize = (input: string) => {
    if (
      input.toLocaleLowerCase() === input ||
      input.toLocaleUpperCase() === input
    ) {
      return capitalize(input);
    }
    return input;
  };
  const capital = capitalizeFirst ? conditionallyCapitalize : (v: string) => v;

  if (first) {
    if (forceFirst) {
      parts.push(capital(user.firstName ?? ''));
      if (
        user.preferredName &&
        user.preferredName !== user.firstName &&
        preferred
      ) {
        parts.push(`'${capital(user.preferredName)}'`);
      }
    } else if (!user.preferredName || !preferred) {
      parts.push(capital(user.firstName ?? ''));
    } else {
      parts.push(capital(user.preferredName ?? ''));
    }
  }
  if (middle) {
    parts.push(user.middleName);
  }
  if (last) {
    if (abbreviateLast) {
      parts.push(`${capitalize(user.lastName?.charAt(0))}.`);
    } else {
      parts.push(capital(user.lastName ?? ''));
    }
  }

  return parts.join(' ');
}

interface Typed {
  readonly __typename?: string;
}
interface UserLike {
  readonly __typename: 'User';
}

/**
 * A common pattern in our API is to query a `viewer` object, which could be one
 * of `User`, `AdminUser`, or `WorksUser`. Our application exclusively uses `User`,
 * so this method will let TypeScript narrow the `viewer` object down to just `User`.
 * @param viewer A GQL object representing some type of user
 * @example
 * if (isUser(data.viewer)) {
 *   console.log(data.viewer.city);
 * }
 */
export const isUser = (viewer?: Typed | null): viewer is UserLike =>
  viewer?.__typename === 'User';

export const maybeUser = <T extends Typed>(viewer?: T | null) =>
  isUser(viewer) ? viewer : null;
