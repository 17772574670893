import type { DefaultOptions } from '@apollo/client';

export const defaultOptions: DefaultOptions = {
  mutate: {
    errorPolicy: 'all',
  },
  query: {
    errorPolicy: 'all',
  },
  watchQuery: {
    errorPolicy: 'all',
  },
};
