import type { IntentFormDataQuery } from '@generated/graphql';
import { gqlDateToDate } from '@src/utils/date';

export const getIntentFormInitialValues = (
  data: IntentFormDataQuery | undefined
) => {
  const earliestStartDateAt = gqlDateToDate(
    data?.nurseProfile?.activationRequest.earliestStartDateAt
  );

  // this is necessary because we consider `asap` 12 weeks from now.
  // but the user needs to see the specific date field if they have a specific date set
  const startPref = earliestStartDateAt
    ? earliestStartDateAt < new Date()
      ? 'asap'
      : 'specific_date'
    : data?.nurseProfile?.nursePreferences?.startPref || 'asap';

  return {
    startPref,
    earliestStartDateAt,
  };
};
