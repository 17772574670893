import { useState, useEffect, useCallback, useRef } from 'react';
import { matchMedia } from '@src/utils/media';

function getCurrentValue<T>(queries: string[], values: T[], noMatchValue: T) {
  const mediaQueryLists = queries.map(matchMedia);
  const matchedQueryLists = mediaQueryLists.filter(
    list => typeof list !== 'undefined' && list.matches
  ) as MediaQueryList[];

  if (!matchedQueryLists.length) {
    return noMatchValue;
  }

  const lastMatchedList = matchedQueryLists[matchedQueryLists.length - 1];
  return values[queries.indexOf(lastMatchedList.media)];
}

/**
 * Hook for observing the window width.
 *
 * If you just need to check for the mobile/desktop threshold consider using the useIsMobile hook instead.
 */
export function useMedia<T>(queries: string[], values: T[], noMatchValue: T) {
  const initialValueSetRef = useRef(false);
  const initialValueRef = useRef<T | undefined>();

  if (!initialValueSetRef.current) {
    initialValueRef.current = getCurrentValue(queries, values, noMatchValue);
    initialValueSetRef.current = true;
  }

  const [value, setValue] = useState(initialValueRef.current);
  const handle = useCallback(
    (mediaQueryList: MediaQueryListEvent) => {
      if (mediaQueryList.matches) {
        setValue(values[queries.indexOf(mediaQueryList.media)]);
      }
    },
    [queries, values]
  );

  useEffect(() => {
    const mediaQueryLists = queries.map(matchMedia);

    mediaQueryLists.forEach(mediaQueryList => {
      if (mediaQueryList?.addEventListener) {
        mediaQueryList?.addEventListener('change', handle);
      } else {
        mediaQueryList?.addListener(handle);
      }
    });

    return () => {
      mediaQueryLists.forEach(mediaQueryList => {
        if (mediaQueryList?.removeEventListener) {
          mediaQueryList?.removeEventListener('change', handle);
        } else {
          mediaQueryList?.removeListener(handle);
        }
      });
    };
  }, [handle, queries, values]);

  return value ?? noMatchValue;
}
