import type { PropsWithChildren } from 'react';
import clsx, { type ClassValue } from 'clsx';

interface OwnProps {
  className?: ClassValue;
}

type Props = PropsWithChildren<OwnProps> & JSX.IntrinsicElements['h4'];

export const PanelHeaderTitle = ({ className, children, ...props }: Props) => {
  const classes =
    'mt-0 mb-0 ml-1 mr-2 full-width self-center font-normal text-lg';

  return (
    <h4 className={clsx(classes, className)} {...props}>
      {children}
    </h4>
  );
};
