import { FontAwesomeIcon } from '@src/ui';

import * as colors from '@src/support/colors';

type Props = {
  topic?: string;
  onClick?: () => void;
};

export function OtherTopics({ topic, onClick }: Props) {
  return (
    <div className="otherTopics" onClick={onClick}>
      <h1 className="labels">{topic}</h1>
      <FontAwesomeIcon
        style={{
          color: colors.black,
          fontSize: 20,
          marginLeft: 16,
        }}
        icon={['fal', 'angle-right']}
      />

      <style jsx>{`
        .otherTopics {
          align-items: center;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          padding-top: 0.875em;
          padding-bottom: 0.875em;
        }

        .labels {
          font-weight: normal;
          font-size: 0.875em;
        }
      `}</style>
    </div>
  );
}
