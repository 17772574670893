import type { NextPageContext } from 'next';

import { notifyError } from '@src/lib/error-reporter';
import { getUserFromCookieString } from '@src/utils/cookies';
import { getCookieString } from '@src/utils/network';
import { NotFoundError, UnexpectedError } from '@src/components/error';

type Props = {
  statusCode?: number;
  error?: unknown;
};

const Error = ({ statusCode }: Props) => {
  if (statusCode === 404) {
    return <NotFoundError />;
  }

  return <UnexpectedError statusCode={statusCode} />;
};

Error.getInitialProps = async (context: NextPageContext) => {
  const statusCode = context.res?.statusCode || context.err?.statusCode || 404;
  const cookieUser = getUserFromCookieString(getCookieString(context.req));

  if (context.err) {
    notifyError(context.err, {
      user: cookieUser,
      extraSections: {
        metadata: {
          Location: context.asPath,
          ErrorContainer: { NextPageContext: context },
        },
      },
    });
  }

  return { statusCode };
};

export default Error;
