import type { PropsWithChildren } from 'react';

export function Nav(props: PropsWithChildren<{}>) {
  return (
    <div className="Nav">
      {props.children}
      <style jsx>{`
        .Nav {
          font-size: 1rem;
          display: flex;
          flex-direction: row;
          // Fallback for https://trusted-health.atlassian.net/browse/AA-485
          height: 100%;
          height: 100dvh;
          width: 100vw;
        }
      `}</style>
    </div>
  );
}
