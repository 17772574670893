import { AnalyticsBrowser } from '@segment/analytics-next';
import getConfig from 'next/config';

const noop = () => undefined;
const resolve = () => Promise.resolve();

const mockSegment = {
  alias: noop,
  debug: noop,
  load: noop,
  page: noop,
  ready: noop,
  reset: resolve,
  track: noop,
  trackLink: noop,
  user: resolve,
  addSourceMiddleware: noop,
  identifyUser: noop,
  identify: noop,
};

const { SEGMENT_WRITE_KEY } = getConfig().publicRuntimeConfig;

const analyticsBrowser = new AnalyticsBrowser();

const segment = SEGMENT_WRITE_KEY ? analyticsBrowser : mockSegment;

type AnalyticsProxy = Pick<
  typeof segment,
  | 'alias'
  | 'debug'
  | 'load'
  | 'page'
  | 'ready'
  | 'reset'
  | 'track'
  | 'trackLink'
  | 'user'
  | 'addSourceMiddleware'
> & {
  identifyUser(user: {
    email: string;
    uuid: string;
    mimic?: boolean;
  }): Promise<void>;
};

type AnalyticsMock = {
  alias: () => void;
  debug: () => void;
  load: () => void;
  page: () => void;
  ready: () => void;
  reset: () => Promise<void>;
  track: () => void;
  trackLink: () => void;
  user: () => Promise<void>;
  addSourceMiddleware: () => void;
  identifyUser: () => Promise<void>;
};

// The point of this proxy is to wrap the global `analytics` method that the
// segment snippet ('@segment/snippet' module) defines in a safety wrapper.
// According to https://github.com/trusted/trusted-web/pull/1311, there are times
// when some of these methods won't be defined, and we don't want the page to barf
// itself when that is the case. So wrap them, and report when them happen.
function createAnalyticsProxy(): AnalyticsProxy | AnalyticsMock {
  return {
    alias: segment.alias.bind(segment),
    debug: segment.debug.bind(segment),
    load: segment.load.bind(segment),
    page: segment.page.bind(segment),
    ready: segment.ready.bind(segment),
    reset: segment.reset.bind(segment),
    track: segment.track.bind(segment),
    trackLink: segment.trackLink.bind(segment),
    user: segment.user.bind(segment),
    addSourceMiddleware: segment.addSourceMiddleware.bind(segment),
    async identifyUser(user) {
      // Prevent mimic'd users from being identified.
      if (user?.mimic || user?.email === 'admin@trustedhealth.com') {
        return;
      }

      await segment.identify(
        user.uuid,
        { email: user.email },
        { integrations: { Intercom: { hideDefaultLauncher: true } } }
      );
    },
  };
}

export const analytics = createAnalyticsProxy();
