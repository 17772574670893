import { FontAwesomeIcon } from '@src/ui';
import * as colors from '@src/support/colors';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';

type Props = {
  icon: IconProp;
  text?: string;
  color?: string;
  backgroundColor?: string;
};

export function IconBox({ icon, text, color = colors.accent03High }: Props) {
  return (
    <div className="outerContainer">
      <div className="iconContainer">
        <FontAwesomeIcon color={color} icon={icon} />
      </div>
      <div className="contentContainer">
        <h1 className="lable">{text}</h1>
        <FontAwesomeIcon
          style={{
            color: colors.black,
            fontSize: 18,
            marginLeft: 16,
          }}
          icon={['fal', 'angle-right']}
        />
      </div>

      <style jsx>{`
        .outerContainer {
          display: flex;
          flex-direction: row;
          width: 100%;
          background-color: ${colors.white};
          padding-bottom: 1rem;
        }
        .iconContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 1.875rem;
          width: 1.875rem;
          border-radius: 1rem;
          border: 0.0625rem solid ${color};
          border-color: ${color};
          margin-right: 8px;
        }
        .contentContainer {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;
          cursor: pointer;
        }
        .lable {
          font-size: 0.875rem;
          font-weight: 700;
        }
      `}</style>
    </div>
  );
}
