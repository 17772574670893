import type { PropsWithChildren } from 'react';
import { makeStyles } from '@src/ui/material-ui';
import clsx, { type ClassValue } from 'clsx';

import * as colors from '@src/support/colors';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    appearance: 'none',
    backgroundColor: 'transparent',
    borderWidth: 0,

    cursor: 'pointer',
    fontSize: '1.75em',
    paddingBottom: 0,
    paddingLeft: '0.35714285714285715em',
    paddingRight: '0.35714285714285715em',
    paddingTop: 0,
  },
  default: {
    color: colors.neutralHighest,
    '&:hover': {
      backgroundColor: colors.neutralLower,
      color: colors.black,
    },
    '&:disabled': {
      backgroundColor: colors.neutralLower,
      color: colors.neutralHighest,
    },
  },
  inverted: {
    color: colors.black,
    '&:hover': {
      color: colors.white,
    },
    '&:disabled': {
      backgroundColor: colors.neutralLower,
      color: colors.neutralHighest,
    },
  },
});

interface OwnProps {
  variant?: 'default' | 'inverted';
  className?: ClassValue;
}

type Props = PropsWithChildren<OwnProps> & JSX.IntrinsicElements['button'];

export const PanelHeaderButton = ({
  variant = 'default',
  className,
  children,
  ...props
}: Props) => {
  const classes = useStyles();
  return (
    <button
      className={clsx(
        classes.root,
        {
          [classes.default]: variant === 'default',
          [classes.inverted]: variant === 'inverted',
        },
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
};
