import {
  type CookieOptions,
  getCookie,
  getCookieFromCookieString,
  getDomain,
  serializeCookie,
  serializeUnsetCookie,
  setCookie,
  unsetCookie,
} from './utils';

const COOKIE_NAME = 'user';
const USER_ROLE_TYPE_COOKIE_NAME = 'userRoleType';
const SIGNUP_COMPLETED_COOKIE_NAME = 'signUpCompleted';

export type CookieUser = {
  authToken: string;
  email: string;
  firstName?: string | null;
  preferredName?: string | null;
  lastName?: string | null;
  adminName?: string;
  adminUserUuid?: string;
  intercomUserHash?: string;
  isAdmin?: boolean;
  state?: string;
  uuid: string;
  // Only set once on signup, not exact same as the backend.
  createdAt?: string;
};

function getUserFromGetCookie(
  getCookie: (cookieName: string) => string | undefined
) {
  const userString = getCookie(COOKIE_NAME);
  if (userString) {
    return JSON.parse(userString) as CookieUser;
  }
}

function stringifyUser(user: CookieUser) {
  // Note: keep type & props explicit here to avoid duck-type leaks.
  const sanitizedUser: CookieUser = {
    uuid: user.uuid,
    authToken: user.authToken,
    email: user.email,
    firstName: user.firstName,
    intercomUserHash: user.intercomUserHash,
    lastName: user.lastName,
    state: user.state,
    createdAt: user.createdAt,
    preferredName: user.preferredName,
    isAdmin: user.isAdmin,
    adminName: user.adminName,
    adminUserUuid: user.adminUserUuid,
  };

  return JSON.stringify(sanitizedUser);
}

export function getUser() {
  return getUserFromGetCookie(getCookie);
}

export function getUserFromCookieString(cookieString: string) {
  return getUserFromGetCookie(cookieName =>
    getCookieFromCookieString(cookieName, cookieString)
  );
}

export function serializeSetUser(
  user: CookieUser,
  cookieOptions: CookieOptions = {}
) {
  if (!user.uuid) {
    throw new Error('User must have a uuid to be serialized.');
  }

  return serializeCookie(COOKIE_NAME, stringifyUser(user), cookieOptions);
}

export function serializeUnsetUser() {
  return serializeUnsetCookie(COOKIE_NAME);
}

export function setUser(user: CookieUser) {
  if (!user.uuid) {
    throw new Error('User must have a uuid to be set.');
  }

  const domain = getDomain();

  // Set user cookie cross domain.
  setCookie(COOKIE_NAME, stringifyUser(user), { domain: domain });
}

export function unsetUser() {
  const domain = getDomain();

  unsetCookie(COOKIE_NAME); // Unset old cookie without domain match
  unsetCookie(COOKIE_NAME, { domain: domain });
  unsetSignUpCompleted();
  unsetUserRoleType();
}

function getSignUpFromGetCookie(
  getCookie: (cookieName: string) => string | undefined
) {
  const signUpCompletedCookie = getCookie(SIGNUP_COMPLETED_COOKIE_NAME);
  if (signUpCompletedCookie) {
    return signUpCompletedCookie;
  }
}

export function getSignUpCompleted(): boolean {
  const signUpCompletedCookie = getSignUpFromGetCookie(getCookie);

  return !!(signUpCompletedCookie && signUpCompletedCookie === 'true');
}

export function setSignUpCompleted(completed: boolean) {
  setCookie(SIGNUP_COMPLETED_COOKIE_NAME, `${completed}`);
}

export function unsetSignUpCompleted() {
  unsetCookie(SIGNUP_COMPLETED_COOKIE_NAME);
}

function unsetUserRoleType() {
  unsetCookie(USER_ROLE_TYPE_COOKIE_NAME);
}
