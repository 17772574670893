// eslint-disable-next-line no-restricted-imports
import type { SerializeOptions } from 'cookie';
// eslint-disable-next-line no-restricted-imports
import * as cookie from 'cookie';

const ONE_MONTH = 30 * 24 * 60 * 60;
const isServer = typeof document === 'undefined';

export type CookieOptions = {
  maxAge?: number;
  path?: string;
  domain?: string;
  sameSite?: SerializeOptions['sameSite'];
  secure?: boolean;
};

export function getCookieFromCookieString(
  cookieName: string,
  cookieString: string
) {
  return cookie.parse(cookieString)[cookieName];
}

export function getCookie(cookieName: string) {
  if (isServer) return;

  return getCookieFromCookieString(cookieName, document.cookie);
}

export function getCookies(
  ...cookieNames: string[]
): Record<string, string | undefined> {
  if (isServer) return {};

  const parsed = cookie.parse(document.cookie);

  return cookieNames.reduce(
    (cookies: Record<string, string | undefined>, cookieName) => {
      cookies[cookieName] = parsed[cookieName];
      return cookies;
    },
    {}
  );
}

const useSecureCookie = ['staging', 'production'].includes(
  String(process.env.RELEASE_STAGE)
);

export function serializeCookie(
  cookieName: string,
  value: string,
  cookieOptions: CookieOptions = {}
) {
  return cookie.serialize(cookieName, value, {
    maxAge: ONE_MONTH,
    path: '/',
    sameSite: 'lax',
    secure: useSecureCookie,
    ...cookieOptions,
  });
}

export function setCookie(
  cookieName: string,
  value: string,
  cookieOptions?: CookieOptions
) {
  if (isServer) return;
  document.cookie = serializeCookie(cookieName, value, cookieOptions);
}

function applyUnsetCookieOptions<T>(
  cookieName: string,
  unsetMethod: (
    cookieName: string,
    value: string,
    cookieOptions: CookieOptions
  ) => T,
  cookieOptions?: CookieOptions
) {
  return unsetMethod(cookieName, '', {
    maxAge: -1,
    ...(cookieOptions || {}),
  });
}

export function serializeUnsetCookie(cookieName: string): string {
  return applyUnsetCookieOptions(cookieName, serializeCookie);
}

export function unsetCookie(cookieName: string, cookieOptions?: CookieOptions) {
  applyUnsetCookieOptions(cookieName, setCookie, cookieOptions);
}

/*
 * Expects a url (e.g. https://app.trustedhealth.com/whatever)
 * Returns the domain (e.g. trustedhealth.com)
 */
function getHostDomainNameFromURL(url: URL) {
  const hostname = url.hostname;
  const components: string[] = hostname.split('.');
  return components.length < 3
    ? components.join('.')
    : components.slice(components.length - 2).join('.');
}

export const getDomain = () => {
  const domain = getHostDomainNameFromURL(new URL(location?.href || ''));

  const ignoreDevDomains = ['localhost', 'trstd.us', 'ngrok'];

  // Ignore localhost domains.
  return ignoreDevDomains.some(devDomain => domain.includes(devDomain))
    ? undefined
    : `.${domain}`;
};
