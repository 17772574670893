import { BaseError } from '@src/components/error/base-error';
import { Button } from '@src/ui';
import { useRouter } from 'next/router';
import { MOBILE_MAX_WIDTH } from '@src/constants';

export const NotFoundError = () => (
  <BaseError
    image="/static/error/not-found-error.png"
    statusCode={404}
    title="Oops, something went wrong."
    details={
      <>
        <p>This page doesn’t exist.</p>
        <p>No worries, let’s get you where you need to go.</p>
      </>
    }
    actions={<NotFoundErrorActions />}
  />
);

const NotFoundErrorActions = () => {
  const router = useRouter();
  return (
    <div className="NotFoundErrorActions">
      <div className="NotFoundErrorActions__primary-button">
        <Button
          color="primary"
          shape="rounded"
          onClick={() => {
            router.push('/home');
          }}
        >
          Home
        </Button>
      </div>
      <div className="NotFoundErrorActions__secondary-label">
        Or try one of these:
      </div>
      <div className="NotFoundErrorActions__secondary-links">
        <Button
          variant="link"
          underline
          onClick={() => router.push('/matches')}
        >
          Matches
        </Button>
        <Button
          variant="link"
          underline
          onClick={() => router.push('/matches/favorites')}
        >
          Favorites
        </Button>
        <Button
          variant="link"
          underline
          onClick={() =>
            window.open(
              'https://help.trustedhealth.com',
              '_blank',
              'noopener,noreferrer'
            )
          }
        >
          Help
        </Button>
      </div>
      <style jsx>{`
        .NotFoundErrorActions {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }

        .NotFoundErrorActions__primary-button {
          display: flex;
          justify-content: center;
        }

        .NotFoundErrorActions__secondary-label {
          display: flex;
          justify-content: center;
          padding-top: 1rem;
        }

        .NotFoundErrorActions__secondary-links {
          display: flex;
          flex: 1;
          flex-direction: row;
          gap: 1rem;
        }

        @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
          .NotFoundErrorActions__secondary-links {
            flex-direction: column;
          }
        }
      `}</style>
    </div>
  );
};
