import {
  createContext,
  type ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';

type TopAppBarState = {
  // text for the page <meta name="description"> tag
  headDescription?: string;
  // text for the page <title> tag
  headTitle?: string;
  // component for what to render as the top bar title
  navTitle?: ReactNode;
  // potential actions for the app bar
  actions?: ReactNode;
  // leading action for the app bar (usually a back button)
  leading?: ReactNode;
  // bottom content **of the top app bar**
  // TODO: in some contexts (scroll behaviors & content reorder) this makes
  //  sense, but at least today in the app there's no big reason to use
  //  this over a simple page "top" content. We might consider
  //  refactoring matches pages to use a simpler top content approach
  bottomContent?: ReactNode;
};

const TopAppBarContext = createContext({
  appBarState: {} as TopAppBarState,
  setAppBarState: (_state: TopAppBarState) => {},
});

export const TopAppBarOverridesProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [state, setState] = useState<TopAppBarState>({});
  const value = useMemo(
    () => ({ appBarState: state, setAppBarState: setState }),
    [state]
  );

  return (
    <TopAppBarContext.Provider value={value}>
      {children}
    </TopAppBarContext.Provider>
  );
};

export const useTopAppBarOverrides = () => useContext(TopAppBarContext);
