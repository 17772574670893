import {
  type ProfileCompletionSummaryFragment,
  ProfileStatusEnum,
} from '@generated/graphql';

export const getProfileStatus = (
  profileCompletionSummary: ProfileCompletionSummaryFragment
): ProfileStatusEnum => {
  const { meta } = profileCompletionSummary;

  if (meta.percentCompleted < 100) {
    if (meta.pendingCount > 0) {
      return ProfileStatusEnum.Pending;
    }

    if (meta.expiredCount > 0) {
      return ProfileStatusEnum.Expired;
    }

    return ProfileStatusEnum.Incomplete;
  }

  if (meta.expiringCount > 0) {
    return ProfileStatusEnum.Expiring;
  }

  return ProfileStatusEnum.Complete;
};
