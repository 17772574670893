import { forwardRef } from 'react';
import type { ReactNode, PropsWithChildren } from 'react';
import clsx, { type ClassValue } from 'clsx';

interface OwnProps {
  header?: ReactNode;
  className?: ClassValue;
}

type Props = PropsWithChildren<OwnProps> & JSX.IntrinsicElements['div'];

export const Panel = forwardRef<HTMLDivElement, Props>(function Panel(
  { className, header, children, ...props },
  ref
) {
  return (
    <div className={clsx('Panel', className)} ref={ref} {...props}>
      {header}
      {children}
      <style jsx>{`
        .Panel {
          overflow-x: hidden;
          overflow-y: auto;
          height: 100%;
        }
      `}</style>
    </div>
  );
});
