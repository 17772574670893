// I don't know if these raw values will ever be needed, but including them just in case
const DESKTOP_MIN_WIDTH_PX_VALUE = 768;
const MATCH_DETAIL_INSIGHTS_BREAKPOINT = 1200;
const SPLIT_VIEW_MATCHES_DESKTOP_MIN_BREAKPOINT = 1200;
const SPLIT_VIEW_MATCHES_MOBILE_MAX_BREAKPOINT = 1199;
export const MOBILE_MAX_WIDTH_PX_VALUE = 767;
export const DESKTOP_MIN_WIDTH = `${DESKTOP_MIN_WIDTH_PX_VALUE}px`;
export const MOBILE_MAX_WIDTH = `${MOBILE_MAX_WIDTH_PX_VALUE}px`;
export const MATCH_DETAIL_HEADER_INSIGHTS_BREAKPOINT = `${MATCH_DETAIL_INSIGHTS_BREAKPOINT}px`;
export const SPLIT_VIEW_MATCHES_DESKTOP_MIN_WIDTH = `${SPLIT_VIEW_MATCHES_DESKTOP_MIN_BREAKPOINT}px`;
export const SPLIT_VIEW_MATCHES_MOBILE_MAX_WIDTH = `${SPLIT_VIEW_MATCHES_MOBILE_MAX_BREAKPOINT}px`;
