import type { RefObject } from 'react';

import { FontAwesomeIcon } from '@src/ui';
import * as colors from '@src/support/colors';
import { neutralLower } from '@src/support/colors';
import { userDisplayName } from '@src/utils/user-utils';
import type { NavigationUserFragment } from '@generated/graphql';

type Props = {
  anchorRef: RefObject<HTMLDivElement>;
  user: NavigationUserFragment | null;
  onClick: () => void;
  open?: boolean;
  clinicianNotNew: boolean;
};

export const NavigationTopBarUser = ({
  anchorRef,
  onClick,
  user,
  open,
}: Props) => {
  const displayName = user
    ? userDisplayName(user, { abbreviateLast: true })
    : '';

  return (
    <div className="NavigationTopBarUser" ref={anchorRef} onClick={onClick}>
      <div className="NavigationTopBarUserContent">
        <img
          className="NavigationTopBarUserImg"
          src={user?.profilePic ?? '/static/profile/empty-flo.png'}
          width={32}
          height={32}
          alt="Profile Photo"
        />

        <div className="NavigationTopBarUserName">
          {displayName}
          <span className="NavigationTopBarUserDown">
            <FontAwesomeIcon
              icon={['fal', open ? 'angle-up' : 'angle-down']}
              fixedWidth
              color={colors.neutralHighest}
            />
          </span>
        </div>
      </div>
      <style jsx>{`
        .NavigationTopBarUser {
          font-weight: 300;
          cursor: pointer;
          border: ${neutralLower} 1px solid;
          border-radius: 6.25rem;
          padding: 0.5rem 0.375rem;
          height: 2.75rem;
          align-self: center;
        }

        .NavigationTopBarUser:hover {
          background-color: ${colors.accent01Lower};
        }

        .NavigationTopBarUserContent {
          height: 100%;
          display: flex;
          flex-flow: row;
          align-items: center;
          position: relative;
          border-radius: 4px;
          gap: 0.375rem;
        }

        .NavigationTopBarUserImg {
          border-radius: 2.5rem;
          width: 2rem;
          height: 2rem;
          border: 1px solid ${colors.neutralDefault};
          overflow: hidden;
          object-fit: cover;
        }

        .NavigationTopBarUserName {
          display: block;
          font-size: 1rem;
          color: ${colors.neutralHighest1};
        }

        .NavigationTopBarUserDown {
          font-size: 1rem;
          color: ${colors.neutralHighest1};
          position: relative;
          top: 0.125rem;
        }
      `}</style>
    </div>
  );
};
