import * as colors from '@src/support/colors';

type Props = {
  title?: string;
  showFeedbackForm: boolean;
  handleYes: () => void;
  handleNo: () => void;
  handleBackToMessages: () => void;
};

export function HelpcenterFooter({
  title,
  showFeedbackForm,
  handleYes,
  handleNo,
  handleBackToMessages,
}: Props) {
  return (
    <div className="container">
      <p className="header">{title}</p>
      <div className="buttonContainer">
        <button
          className="helpCenterBtnLeft"
          onClick={showFeedbackForm ? handleYes : handleBackToMessages}
        >
          {showFeedbackForm ? 'Yes' : 'Back to Messages'}
        </button>
        {showFeedbackForm ? (
          <button className="helpCenterBtnRight" onClick={handleNo}>
            No, I still need help
          </button>
        ) : null}
      </div>
      <style jsx>{`
        .container {
          width: 100%;
          padding: 1rem;
          background-color: ${colors.neutralLower};
        }
        .header {
          font-size: 1rem;
          font-weight: 700;
          margin-bottom: 1rem;
        }
        .buttonContainer {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
        .helpCenterBtnRight {
          width: 100%;
          margin-left: 0.5rem;
          padding: 0.625rem;
          border-radius: 2.25rem;
          border: 0.125rem solid ${colors.neutralDefault};
          background-color: ${colors.white};
          font-weight: 700;
          font-size: 1rem;
        }
        .helpCenterBtnLeft {
          width: 100%;
          margin-right: 0.5rem;
          padding: 0.625rem;
          border-radius: 2.25rem;
          border: 0.125rem solid ${colors.neutralDefault};
          background-color: ${colors.white};
          font-weight: 700;
          font-size: 1rem;
        }
      `}</style>
    </div>
  );
}
