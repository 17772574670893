import HeartbeatSvg from '@public/static/svgs/heartbeat.svg';
import * as colors from '@src/support/colors';

type Props = {
  message?: string;
};

export function LoadingOverlay({ message = 'Loading' }: Props) {
  return (
    <div className="LoadingOverlay">
      <strong className="LoadingMessage">{message}</strong>
      <div className="HeartRateContainer">
        <HeartbeatSvg />
      </div>
      <style jsx>{`
        .LoadingOverlay {
          display: flex;
          justify-content: center;
          height: 80vh;
          align-items: center;
          flex-direction: column;
        }

        .LoadingMessage {
          font-weight: 500;
          font-size: 1.3125em;
          text-align: center;
          color: ${colors.neutralHighest1};
        }

        .HeartRateContainer {
          padding-top: 1.3125rem;
        }

        .HeartRateContainer > :global(svg) {
          animation: heart-rate 2s linear infinite;
          stroke-dasharray: 480;
          stroke-width: 6px;
          stroke-linecap: round;
          will-change: stroke-dashoffset;
          stroke: ${colors.primary01};
        }

        @keyframes heart-rate {
          0% {
            stroke-dashoffset: 575;
          }

          100% {
            stroke-dashoffset: 1440;
          }
        }
      `}</style>
    </div>
  );
}
