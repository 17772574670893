export const USER_VIEWED_CHAT_PANEL = 'User Viewed Chat Panel';
export const CLICKED_TEXT_ADVOCATE = 'Clicked text advocate';
export const CLICKED_CALL_ADVOCATE = 'Clicked call advocate';
export const CLICKED_CHAT_ADVOCATE = 'Clicked chat advocate';
export const USER_CLICKED_SAVE_JOB_SEARCH_STATUS_PREFERENCES =
  'User Clicked Save Job Search Status Preferences';
export const USER_CLICKED_UPDATE_JOB_SEARCH_STATES =
  'User Clicked Update Job Search States';
export const USER_CLICKED_READY_TO_APPLY_BUTTON =
  'User Clicked Ready to Apply Button';

export const USER_CLICKED_CONFIRM_EMAIL_ADDRESS_TASK =
  'User Clicked Confirmed Email Address Task';
export const USER_CLICKED_YEARS_OF_EXPERIENCE_TASK =
  'User Clicked Years of Experience Task';
export const USER_CLICKED_ROLE_AND_SPECIALTY_TASK =
  'User Clicked Role and Specialty';
export const USER_CLICKED_SKILLS_CHECKLIST_TASK =
  'User Clicked Skills Checklist Task';
export const USER_CLICKED_WORK_EXPERIENCE_TASK =
  'User Clicked Work Experience Task';
export const USER_CLICKED_EDUCATION_TASK = 'User Clicked Education Task';
export const USER_CLICKED_LICENSE_OR_CERTIFICATION_TASK =
  'User Clicked License or Certification Task';
export const USER_CLICKED_LICENSE_TASK = 'User Clicked License Task';
export const USER_CLICKED_CERTIFICATION_TASK =
  'User Clicked Certification Task';
export const USER_CLICKED_REFERENCES_TASK = 'User Clicked References Task';
export const USER_CLICKED_PERSONAL_INFORMATION_TASK =
  'User Clicked Personal Information Task';
export const USER_CLICKED_BACKGROUND_QUESTIONS_TASK =
  'User Clicked Background Questions Task';
