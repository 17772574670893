import type { PropsWithChildren } from 'react';

import { white } from '@src/support/colors';

export const NavMainContent = ({ children }: PropsWithChildren<{}>) => (
  <div className="NavMainContent">
    {children}
    <style jsx>{`
      .NavMainContent {
        background-color: ${white};
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        max-width: 100vw;
      }
    `}</style>
  </div>
);
