import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  withinPanel?: boolean;
}>;

export const NavMainHeaderTabsList = ({
  children,
  withinPanel = false,
}: Props) => (
  <ul className="NavMainHeaderTabsList">
    {children}
    <style jsx>
      {`
        .NavMainHeaderTabsList {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-grow: 1;
        }

        @media screen and (min-width: 24rem) {
          .NavMainHeaderTabsList {
            display: inline-flex;
            ${withinPanel ? '' : 'flex-grow: 0.5;'}
          }
        }
      `}
    </style>
  </ul>
);
