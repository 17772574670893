import {
  createContext,
  type ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';

type BottomAppBarState = {
  hideBottom?: boolean;
};

const BottomAppBarContext = createContext({
  bottomBarState: {} as BottomAppBarState,
  setBottomBarState: (_state: BottomAppBarState) => {},
});

export const BottomAppBarOverridesProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [state, setState] = useState<BottomAppBarState>({});
  const value = useMemo(
    () => ({ bottomBarState: state, setBottomBarState: setState }),
    [state]
  );

  return (
    <BottomAppBarContext.Provider value={value}>
      {children}
    </BottomAppBarContext.Provider>
  );
};

export const useBottomAppBarOverrides = () => useContext(BottomAppBarContext);
