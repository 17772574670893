import { createContext } from 'react';

import type { JobsPageSearchParams } from './types';

interface JobsPageContextData {
  newQueryParams?: JobsPageSearchParams | undefined;
  setNewQueryParams?: (value: JobsPageSearchParams | undefined) => void;
}

export const JobsPageContext = createContext<JobsPageContextData>({});
