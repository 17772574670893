import type { ReactNode } from 'react';
import { getIn, type FormikErrors, type FormikTouched } from '@src/ui/formik';

export const hasError = <T = unknown>(
  name: string | string[],
  touched: FormikTouched<T>,
  errors: FormikErrors<T>
) => !!(getIn(touched, name) && getIn(errors, name));

export const getHelperText = <T = unknown>(
  name: string | string[],
  touched: FormikTouched<T>,
  errors: FormikErrors<T>,
  text: ReactNode = null
): ReactNode => {
  if (hasError(name, touched, errors)) {
    const err = getIn(errors, name);
    return Array.isArray(err) ? err.join('\n') : err;
  }
  return text;
};

export const getInNonNull = (
  object: unknown,
  key: string | string[],
  defaultValue: unknown
) => {
  const get = getIn(object, key, defaultValue);
  return get === null ? defaultValue : get;
};
