import { IntercomArticlesDocument } from '@generated/graphql';
import { useQuery } from '@src/hooks/use-graphql-hooks';
import { analytics } from '@src/lib/analytics';
import { notifyErrorCallback } from '@src/lib/error-reporter';
import * as colors from '@src/support/colors';
import { removeAmpersand } from '@src/utils/help-center';

import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import { ChatPrompt } from './chat-prompt';
import { HelpcenterCard } from './helpcenter-card';
import { OtherTopics } from './other-topics';
import type { ArticleItem } from './types';
import { SEGMENT_EVENTS } from '@src/constants';

type Props = {
  setHeaderTitle: (title: string) => void;
  setLoading: (loading: boolean) => void;
  topic: string;
  subtopic?: string;
  handleSubmit: () => void;
  onNext: () => void;
  showFeedbackForm: boolean;
  sectionId: string;
  sectionParentType: string;
  setArticleId: Dispatch<SetStateAction<string>>;
  setArticleName: Dispatch<SetStateAction<string>>;
  setArticleOpen: Dispatch<SetStateAction<boolean>>;
};

export function SubmitQuestion({
  setHeaderTitle,
  setLoading,
  topic,
  subtopic,
  handleSubmit,
  onNext,
  showFeedbackForm,
  sectionId,
  sectionParentType,
  setArticleId,
  setArticleName,
  setArticleOpen,
}: Props) {
  const [articles, setArticles] = useState<ArticleItem[]>([]);

  const { data, loading } = useQuery(IntercomArticlesDocument, {
    variables: { parentId: parseInt(sectionId), parentType: sectionParentType },
    onError: notifyErrorCallback('Article Selection - Intercom articles list'),
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setArticles(data?.articles as ArticleItem[]);
    setHeaderTitle(`${topic}: ${subtopic}`);
  }, [setHeaderTitle, data?.articles, topic, subtopic]);

  useEffect(() => setLoading(loading), [loading, setLoading]);

  return (
    <div className="container">
      {showFeedbackForm ? (
        <HelpcenterCard title="Articles that might help">
          {articles?.map((item, i) => (
            <OtherTopics
              key={i}
              topic={removeAmpersand(item.title)}
              onClick={() => {
                analytics.track(SEGMENT_EVENTS.CHAT_PANEL.ARTICLE_CLICK, {
                  source: 'Inbox Web',
                  articleName: item.title,
                  topic: topic,
                  articleId: item.id,
                  subTopic: subtopic,
                });
                setArticleId(item.id);
                setArticleName(removeAmpersand(item.title));
                setArticleOpen(true);
                onNext();
              }}
            />
          ))}
        </HelpcenterCard>
      ) : null}
      <ChatPrompt
        topic={topic}
        subtopic={subtopic ?? ''}
        handleSubmit={handleSubmit}
      />
      <style jsx>{`
        .container {
          width: 100%;
        }
        .header {
          align-items: center;
          display: flex;
          flex-direction: row;
          padding-bottom: 0.5em;
          border-bottom: 2px solid ${colors.neutralHighest1};
          margin-bottom: 0.5em;
        }
        .topicHeader {
          font-weight: bold;
          font-size: 1em;
          padding-right: 0.25em;
        }
        .topicText {
          color: ${colors.neutralHighest1};
          font-size: 1em;
          font-weight: bold;
        }
      `}</style>
    </div>
  );
}
