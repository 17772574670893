import { useCallback, useEffect } from 'react';
import { Panel } from '@src/ui';
import * as sunshineService from '@src/lib/sunshine';
import { analytics } from '@src/lib/analytics';
import { SEGMENT_EVENTS } from '@src/constants';

type Props = {
  onError: () => void;
  onClickNewConversation: () => void;
  setInitialState: () => void;
  setLoading: (loading: boolean) => void;
  initialMessage?: string;
  topic: string;
  subtopic: string;
  articleId: string;
  articleName: string;
};

export function MessagesInbox({
  onError,
  onClickNewConversation,
  setInitialState,
  setLoading,
  initialMessage,
  topic,
  subtopic,
  articleId,
  articleName,
}: Props) {
  const bindNewConvoButton = useCallback(
    (sunshineFrame: HTMLIFrameElement) => {
      const newConvoButton =
        sunshineFrame.contentWindow?.document.querySelector(
          '.conversation-group-footer > button'
        ) as HTMLButtonElement | null | undefined;
      if (newConvoButton) {
        // Hijack widget convo button to trigger our decision tree
        newConvoButton.addEventListener('click', evt => {
          evt.preventDefault();
          evt.stopPropagation();
          analytics.track(SEGMENT_EVENTS.CHAT_PANEL.START_NEW_CONVERSATION, {
            source: 'Messages Page Web',
          });
          onClickNewConversation();
        });
      }
    },
    [onClickNewConversation]
  );

  useEffect(() => {
    const existingEmbed = document.getElementById('web-messenger-container');
    // setInterval and setTimeout have different return types in the browser vs nodejs
    let sunshinePoller: ReturnType<typeof setInterval>;
    let pollerTimeout: ReturnType<typeof setTimeout>;
    if (!existingEmbed) {
      window.TrustedHealthSunshine.render(
        document.getElementById('ChatContainer')!
      );

      sunshinePoller = setInterval(function () {
        const sunshineFrame = document.getElementById(
          'web-messenger-container'
        ) as HTMLIFrameElement;

        if (!sunshineFrame) {
          // Delay for the conversation to load
          setTimeout(() => setLoading(false), 1500);
          return;
        }

        const chatContainer =
          sunshineFrame.contentWindow?.document.getElementById('container');
        if (!chatContainer) {
          // Delay for the conversation to load
          setTimeout(() => setLoading(false), 1500);
          return;
        }

        clearInterval(sunshinePoller);
        clearTimeout(pollerTimeout);

        bindNewConvoButton(sunshineFrame);

        // Add observer to Sunshine iFrame conversation container to
        // determine if conversation button has been added again
        const observer = new MutationObserver(mutations => {
          mutations.forEach(mutation => {
            mutation.addedNodes.forEach(node => {
              const castNode = node as HTMLElement | undefined;

              if (
                castNode?.classList?.contains('conversation-list-container')
              ) {
                bindNewConvoButton(sunshineFrame);
              }
            });
          });
        });

        observer.observe(chatContainer, {
          childList: true,
          subtree: true,
        });

        if (initialMessage && initialMessage !== '') {
          sunshineService.createAndLoadConversation(
            initialMessage,
            topic,
            subtopic,
            articleId,
            articleName
          );
          // Delay for the conversation to load
          setTimeout(() => setLoading(false), 1000);
        } else if (
          window.TrustedHealthSunshine!.getConversations!().length === 0
        ) {
          setInitialState();
        } else {
          setLoading(false);
        }
      }, 100);

      pollerTimeout = setTimeout(() => {
        clearInterval(sunshinePoller);
        onError();
      }, 10000);
    }

    return () => {
      if (pollerTimeout) {
        clearTimeout(pollerTimeout);
      }
      if (sunshinePoller) {
        clearInterval(sunshinePoller);
      }
    };
  }, [
    initialMessage,
    setInitialState,
    setLoading,
    onError,
    bindNewConvoButton,
    topic,
    subtopic,
    articleId,
    articleName,
  ]);

  return (
    <Panel>
      <div id="ChatContainer" />
      <style jsx>{`
        #ChatContainer {
          height: 100%;
        }
      `}</style>
    </Panel>
  );
}
