import type { PropsWithChildren, ReactNode } from 'react';
import clsx from 'clsx';

import * as colors from '@src/support/colors';

type Props = {
  text?: ReactNode;
  color?: 'secondary' | 'success';
  style?: React.CSSProperties;
  elementType?: 'icon' | 'text-right';
};

export function Badge({
  children,
  color,
  elementType,
  style,
  text,
}: PropsWithChildren<Props>) {
  const hasText = !!text;

  return (
    <span className="Badge" style={style}>
      {children}
      <span
        className={clsx('BadgeIndicator', {
          'BadgeIndicator--color-secondary': color === 'secondary',
          'BadgeIndicator--color-success': color === 'success',
          'BadgeIndicator--text-right': elementType === 'text-right',
        })}
      >
        {text}
      </span>
      <style jsx>{`
        .Badge {
          display: inline-flex;
          position: relative;
          align-items: center;
          justify-content: center;
        }

        .BadgeIndicator {
          align-items: center;
          border-radius: 1.25rem;
          display: inline-flex;
          font-size: 0.5rem;
          line-height: 0.3125rem;
          font-weight: bold;
          width: ${hasText ? '1.125rem' : '0.625rem'};
          height: ${hasText ? '1.125rem' : '0.625rem'};
          justify-content: center;
          min-width: ${hasText ? '1.125rem' : '0.625rem'};
          pointer-events: none;
          position: absolute;
          left: ${hasText ? '60%' : '70%'};
          top: ${hasText ? '-50%' : '-20%'};
          border: 1px solid ${colors.white};
        }

        .BadgeIndicator--text-right {
          margin-left: 0.5em;
          left: 100%;
          top: 0;
        }

        .BadgeIndicator--color-secondary {
          background-color: ${colors.semanticErrorHigh};
          color: ${colors.white};
        }

        .BadgeIndicator--color-success {
          background-color: ${colors.semanticSuccess};
          color: ${colors.white};
        }
      `}</style>
    </span>
  );
}
