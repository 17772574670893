import { notifyErrorCallback } from '@src/lib/error-reporter';
import getConfig from 'next/config';

const { SUNSHINE_INTEGRATION_ID } = getConfig().publicRuntimeConfig;

type SunshineConversation = {
  id: string;
};

type SunshineClient = {
  VERSION: string;
  init: (options: {
    integrationId: string;
    externalId: string;
    jwt: string | undefined | null;
    embedded: boolean;
    menuItems: {
      imageUpload: boolean;
      fileUpload: boolean;
      shareLocation: boolean;
    };
    customColors: {
      brandColor: string;
      conversationColor: string;
      actionColor: string;
    };
    customText: {
      messageDelivered: string;
      messageSeen: string;
      newConversationButtonText: string;
    };
  }) => Promise<void>;
  render: (root: HTMLElement) => void;
  destroy: () => void;
  // We potentially have more events, these are the ones we found in sunshine
  // source (source mapped from webpack)
  on(event: 'widget:closed' | 'widget:opened', callback: () => void): void;
  on(
    event: 'connected' | 'disconnected' | 'reconnecting',
    callback: (values: { conversation: SunshineConversation }) => void
  ): void;
  createConversation?: (settings: {
    metadata: {
      isArchived: boolean;
      Topic: string;
      Subtopic: string;
      articleId: string | undefined;
      articleName: string | undefined;
    };
    messages: {
      text: string;
      type: 'text';
    }[];
  }) => Promise<SunshineConversation>;
  loadConversation?: (conversationId: string) => void;
  getConversations?: () => SunshineConversation[];
  off?: (
    event:
      | 'widget:closed'
      | 'widget:opened'
      | 'connected'
      | 'disconnected'
      | 'reconnecting',
    handler?: () => void
  ) => void;

  // These we don't use, but are available, so we're just listing.
  close?: unknown;
  getConversationById?: unknown;
  getDisplayedConversation?: unknown;
  getMoreConversations?: unknown;
  getUnreadCount?: unknown;
  getUser?: unknown;
  hasMoreConversations?: unknown;
  isOpened?: unknown;
  login?: unknown;
  logout?: unknown;
  markAllAsRead?: unknown;
  open?: unknown;
  sendMessage?: unknown;
  setDelegate?: unknown;
  setPredefinedMessage?: unknown;
  showNotificationChannelPrompt?: unknown;
  startTyping?: unknown;
  stopTyping?: unknown;
  triggerPostback?: unknown;
  updateConversation?: unknown;
  updateUser?: unknown;
};

declare global {
  interface Window {
    TrustedHealthSunshine: SunshineClient;
    smoochInitialized: boolean;
  }
}

export const init = (user?: { id: string; sunshineToken?: string | null }) => {
  if (!user || typeof window === 'undefined') {
    return;
  }

  window.TrustedHealthSunshine?.init?.({
    integrationId: SUNSHINE_INTEGRATION_ID,
    externalId: user.id,
    jwt: user.sunshineToken,
    embedded: true,
    menuItems: {
      imageUpload: true,
      fileUpload: true,
      shareLocation: false,
    },
    customColors: {
      brandColor: '1d8292',
      conversationColor: 'none',
      actionColor: '6142B5',
    },
    customText: {
      messageDelivered: 'Pending',
      messageSeen: 'Delivered',
      newConversationButtonText: 'Create New Message',
    },
  })
    .then(() => {
      window.smoochInitialized = true;

      return { smoochInitialized: true };
    })
    .catch(notifyErrorCallback('Sunshine Init Error'));
};

export const destroy = () => {
  if (
    typeof window !== 'undefined' &&
    typeof window.TrustedHealthSunshine?.destroy === 'function'
  ) {
    window.TrustedHealthSunshine.destroy();
    window.smoochInitialized = false;
  }
};

export const createAndLoadConversation = (
  messageText: string,
  topic: string,
  subtopic: string,
  articleId?: string,
  articleName?: string
) => {
  if (typeof window === 'undefined') {
    return;
  }

  window.TrustedHealthSunshine?.createConversation?.({
    metadata: {
      isArchived: false,
      Topic: topic,
      Subtopic: subtopic,
      articleId: articleId,
      articleName: articleName,
    },
    messages: [
      {
        text: messageText,
        type: 'text',
      },
    ],
  })
    .then(conversation => {
      window.TrustedHealthSunshine?.loadConversation?.(conversation.id);
    })
    .catch(notifyErrorCallback('Sunshine Conversation Error'));
};
