import type { PropsWithChildren } from 'react';

type Props = {
  textAlign?: 'left' | 'center' | 'right';
};

export function ModalTitle({
  children,
  textAlign = 'center',
}: PropsWithChildren<Props>) {
  return (
    <div className="ModalTitle">
      {children}
      <style jsx>{`
        .ModalTitle {
          font-size: 1.25em;
          font-weight: bold;
          line-height: 1.35em;
          text-align: ${textAlign};
        }
      `}</style>
    </div>
  );
}
