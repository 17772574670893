import { Box } from '@src/ui/material-ui';
import * as colors from '@src/support/colors';
import { FontAwesomeIcon } from '@src/ui';
import type { PropsWithChildren } from 'react';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';

type Props = {
  icon?: IconProp;
  title?: string;
  topic?: string;
};

export function HelpcenterCard({
  children,
  icon,
  title,
}: PropsWithChildren<Props>) {
  return (
    <Box
      pl={2}
      pr={2}
      pt={2}
      pb={2}
      mt={2}
      mb={3}
      boxShadow={2}
      borderRadius="4px"
    >
      {title ? (
        <div className="header">
          {icon ? (
            <div className="iconContainer">
              <FontAwesomeIcon
                style={{ fontSize: 16, margin: 12 }}
                color={colors.accent03High}
                icon={icon}
              />
            </div>
          ) : null}
          <h1 className="topicHeader">{title}</h1>
        </div>
      ) : null}
      {children}
      <style jsx>{`
        .header {
          align-items: center;
          display: flex;
          flex-direction: row;
          padding-bottom: 0.5rem;
          border-bottom: 2px solid ${colors.neutralHighest1};
          margin-bottom: 0.5rem;
        }
        .iconContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 1.75rem;
          width: 1.75rem;
          border-radius: 1.75rem;
          border: 0.0625rem solid ${colors.accent03High};
          border-color: ${colors.accent03High};
          margin-right: 0.5rem;
        }
        .topicHeader {
          font-weight: bold;
          font-size: 1rem;
        }
      `}</style>
    </Box>
  );
}
