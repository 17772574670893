import * as colors from '@src/support/colors';
import Link from 'next/link';
import { userDisplayName } from '@src/utils/user-utils';
import type { UserFullNameFragment } from '@generated/graphql';

type Props = {
  isAdmin?: boolean;
  adminName?: string;
  user: UserFullNameFragment;
};

export function AdminSessionBanner({
  isAdmin = false,
  adminName,
  user,
}: Props) {
  if (isAdmin === false) return null;

  const displayName = userDisplayName(user, { forceFirst: true });
  return (
    <div className="AdminSessionBanner">
      <div className="AdminSessionBannerContent">
        Hello {adminName}, be careful, you&apos;re signed in as {displayName}.{' '}
        <Link href="/logout" legacyBehavior>
          <a>Sign Out</a>
        </Link>
      </div>
      <style jsx>{`
        .AdminSessionBanner {
          background-color: ${colors.semanticErrorHigh};
          color: ${colors.white};
          text-align: center;
          width: 100%;
          overflow: hidden;
          top: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .AdminSessionBannerContent {
          margin-bottom: 0;
          margin-left: auto;
          margin-right: auto;
          margin-top: 0;
          overflow: hidden;
          text-align: center;
        }

        .AdminSessionBannerContent > :global(a) {
          color: ${colors.white};
          text-decoration: underline;
        }
      `}</style>
    </div>
  );
}
