import { useEffect } from 'react';

import { getUser } from '@src/utils/cookies';
import { analytics } from '@src/lib/analytics';

export const useRootIdentifySessionEffect = () => {
  useEffect(() => {
    const syncIds = async () => {
      const user = getUser();
      const identifiedId = (await analytics.user())?.id();

      if (user) {
        // This should happen on "become", thus why we don't notify any error
        if (identifiedId && identifiedId !== user.uuid) {
          // We reset between overrides to avoid attribution problems, as per Segment documentation:
          // @see https://segment.com/docs/connections/spec/best-practices-identify/#id-expiration-and-overwriting
          analytics.reset();
        }

        // Make sure we extend expiration date of whatever cookie
        // analytics uses to identify the user.
        analytics.identifyUser(user);
      } else if (identifiedId) {
        // When the user cookie expires the getUser will return undefined
        // at the server side, and we'll redirect the user to directly login
        // without the reset calls, this handles that mismatch.
        analytics.reset();
      }
    };

    syncIds();
  }, []);
};
