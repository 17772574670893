import Link from 'next/link';
import clsx from 'clsx';
import type { MouseEvent } from 'react';

import { FontAwesomeIcon } from '@src/ui/font-awesome-icon';
import TrustedLogomarkFullBlack from '@public/static/trusted_wordmark_black.svg';
import * as colors from '@src/support/colors';
import { userDisplayName } from '@src/utils/user-utils';
import { Badge, Button, NavSideMenu, Scroll, ScrollView } from '@src/ui';
import type { NavigationLinkDefinition } from '@src/components/navigation/navigation-link-definition';
import type { NavigationUserFragment } from '@generated/graphql';
import { useIsCurrentLink } from '@src/components/navigation/use-is-current-link';
import { SEGMENT_EVENTS } from '@src/constants';
import { useTrackNavigationClick } from './use-track-navigation-click';
import { useMobileWebview } from '@src/hooks/use-mobile-webview';
import { ReferLink } from '@src/components/refer-link';

export const NavigationSideMenu = ({
  open,
  onClose,
  user,
  referText,
  links,
  extraLinks,
}: {
  open: boolean;
  onClose: () => void;
  user: NavigationUserFragment | null;
  referText: string | undefined | null | false;
  links: NavigationLinkDefinition[];
  extraLinks: NavigationLinkDefinition[];
}) => {
  const webView = useMobileWebview();
  const isCurrentLink = useIsCurrentLink();
  const trackNavigation = useTrackNavigationClick();

  const onInviteLinkClick = () => {
    trackNavigation(SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_INVITE_LINK);
    onClose();
  };

  const onLogoutLinkClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (webView.isPresent) {
      event.preventDefault();
      webView.postMessage({ action: 'LOGOUT' });
    }

    trackNavigation(SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_LOGOUT_LINK);
  };

  const handleClick =
    (forward: undefined | (() => void)) => (event: MouseEvent<unknown>) => {
      event.stopPropagation();
      forward?.();
      onClose();
    };

  const renderItem = (
    item: NavigationLinkDefinition & { hideIcon?: boolean }
  ) => {
    const isCurrent = isCurrentLink(item.href);
    const classNames = clsx('link-item h-padding', {
      current: isCurrent,
    });

    const itemIcon = (
      <FontAwesomeIcon
        icon={isCurrent ? item.activeIcon : item.icon}
        fixedWidth
      />
    );

    const icon = item.hideIcon ? null : item.count ? (
      <Badge color="secondary">{itemIcon}</Badge>
    ) : (
      itemIcon
    );

    if (!item.href) {
      return (
        <div
          key={item.label}
          onClick={handleClick(item.onClick)}
          className={classNames}
        >
          {icon}
          <div className={clsx('item-label', { 'icon-hidden': item.hideIcon })}>
            {item.label}
          </div>
          <LinkIndicator />
        </div>
      );
    }

    return (
      <Link
        key={item.href}
        href={item.href}
        onClick={handleClick(item.onClick)}
        className={classNames}
      >
        {icon}
        <div className={clsx('item-label', { 'icon-hidden': item.hideIcon })}>
          {item.label}
        </div>
        <LinkIndicator />
      </Link>
    );
  };

  return (
    <NavSideMenu isOpen={open} onClose={onClose}>
      <div className="NavigationSideMenu">
        <div className="h-padding">
          <LogoHeader onClose={onClose} />
          <UserHeader user={user} />
        </div>
        <div className="divider h-padding" />
        <Scroll>
          <ScrollView>
            <div>
              {!!referText && (
                <div className="v-padding h-padding">
                  <ReferLink text={referText} onClick={onInviteLinkClick} />
                </div>
              )}
              {links.map(renderItem)}
            </div>
            <div className="divider h-padding t-margin" />
            <div className="t-padding">
              {extraLinks.map(link => renderItem({ ...link, hideIcon: true }))}
              <Button
                style={{
                  padding: '1rem',
                  borderWidth: 0,
                  lineHeight: '1rem',
                  textUnderlineOffset: '.2rem',
                }}
                component="a"
                size="medium"
                variant="hollowWhite"
                border="transparentOnHover"
                underline
                href="/logout"
                onClick={onLogoutLinkClick}
              >
                <div>Log Out</div>
              </Button>
            </div>
          </ScrollView>
        </Scroll>
      </div>
      <style jsx>{`
        .NavigationSideMenu {
          display: flex;
          flex-direction: column;
          height: 100%;
        }

        .NavigationSideMenu :global(.self-end) {
          align-self: end;
        }

        .NavigationSideMenu :global(.t-margin) {
          margin-top: 1rem;
        }

        .NavigationSideMenu :global(.t-padding) {
          padding-top: 1rem;
        }

        .NavigationSideMenu :global(.v-padding) {
          padding-top: 1rem;
          padding-bottom: 1rem;
        }

        .NavigationSideMenu :global(.h-padding) {
          padding-left: 1rem;
          padding-right: 1rem;
        }

        .NavigationSideMenu .divider.h-padding {
          margin-left: 1rem;
          margin-right: 1rem;
        }

        .NavigationSideMenu .divider {
          border-top: 1px solid ${colors.neutralDefault};
        }

        .NavigationSideMenu :global(.link-item) {
          -webkit-tap-highlight-color: transparent;
          display: flex;
          cursor: pointer;
          flex-direction: row;
          padding-top: 0.75rem;
          padding-bottom: 0.75rem;
          align-items: center;
          width: 100%;
          transition: background-color 300ms;
        }

        .NavigationSideMenu :global(.link-item:hover),
        .NavigationSideMenu :global(.link-item.current) {
          background-color: ${colors.accent01Lowest};
          font-weight: 600;
        }

        .NavigationSideMenu :global(.link-item:focus) {
          background-color: ${colors.accent01Lower};
        }

        .NavigationSideMenu :global(.link-item .item-label) {
          flex: 1;
          padding-left: 0.5rem;
        }

        .NavigationSideMenu :global(.link-item .item-label.icon-hidden) {
          padding-left: 0;
        }

        .NavigationSideMenu :global(.link-item.logout .item-label) {
          font-weight: bold;
          text-decoration: underline;
        }
      `}</style>
    </NavSideMenu>
  );
};

const LogoHeader = ({ onClose }: { onClose: () => void }) => (
  <div className="LogoHeader">
    <div className="logo">
      <TrustedLogomarkFullBlack width="110" />
    </div>
    <div className="close">
      <FontAwesomeIcon
        icon={['fal', 'times']}
        color={colors.black}
        fontSize="1.5rem"
        onClick={onClose}
      />
    </div>
    <style jsx>
      {`
        .LogoHeader {
          display: flex;
          padding: 1rem 0;
          align-items: center;
        }

        .LogoHeader .logo {
          display: flex;
          flex: 1;
          align-items: center;
        }

        .LogoHeader :global(.close) {
          cursor: pointer;
          display: flex;
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 0.25rem;
          align-items: center;
          justify-content: center;
        }

        .LogoHeader .close:focus,
        .LogoHeader .close:hover {
          background-color: ${colors.neutralLower};
        }

        .LogoHeader .close:active {
          background-color: ${colors.accent01Lower};
        }
      `}
    </style>
  </div>
);

const UserHeader = ({ user }: { user: NavigationUserFragment | null }) => (
  <div className="UserHeader">
    <div className="profile-pic">
      <img
        src={user?.profilePic ?? '/static/profile/empty-flo.png'}
        alt="Profile Photo"
      />
    </div>
    <div className="user-info">
      <div className="user-name">{user && userDisplayName(user)}</div>
      <div className="user-role">{user?.nurseProfile?.role?.name ?? ''}</div>
    </div>
    <style jsx>
      {`
        .UserHeader {
          display: flex;
          flex-direction: row;
          padding: 1rem 0;
        }

        .UserHeader .profile-pic {
          display: flex;
          align-items: center;
        }

        .UserHeader img {
          border-radius: 2.5rem;
          width: 3rem;
          height: 3rem;
          border: 1px solid ${colors.primary01};
          overflow: hidden;
          object-fit: cover;
        }

        .UserHeader .user-info {
          flex: 1;
          flex-direction: column;
          padding-left: 8px;
          align-content: center;
        }

        .UserHeader .user-name {
          padding-bottom: 2px;
          font-weight: bold;
        }

        .UserHeader .user-role {
          font-weight: 400;
          color: ${colors.neutralHighest};
        }
      `}
    </style>
  </div>
);

const LinkIndicator = () => (
  <div className="LinkIndicator">
    <FontAwesomeIcon icon={['fal', 'chevron-right']} />
    <style jsx>{`
      .LinkIndicator {
        display: flex;
        width: 1.5rem;
        height: 1.5rem;
        align-items: center;
        justify-content: center;
      }
    `}</style>
  </div>
);
