import type { PropsWithChildren } from 'react';

import * as Colors from '@src/support/colors';

type Props = {
  onClick?: () => void;
};

export const NavMainHeaderBarTitle = (props: PropsWithChildren<Props>) => (
  <h2 className="NavMainHeaderBarTitle" onClick={props.onClick}>
    {props.children}
    <style jsx>
      {`
        .NavMainHeaderBarTitle {
          align-self: center;
          background-color: ${Colors.white};
          flex: 1;
          font-size: 1.125rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: ${Colors.black};
        }
      `}
    </style>
  </h2>
);
