import { useRouter } from 'next/router';
import { useCallback } from 'react';

export const useIsCurrentLink = () => {
  const { pathname: activeRoute } = useRouter();

  return useCallback(
    (itemRoute?: string): boolean => {
      const possibleApplyButtonRoutes = [
        '/matches/favorites',
        '/matches/applications',
      ];

      if (itemRoute?.includes('/matches')) {
        if (activeRoute?.includes('/matches/applications')) {
          return possibleApplyButtonRoutes.includes(itemRoute);
        }

        if (activeRoute?.includes('/matches/favorites')) {
          return possibleApplyButtonRoutes.includes(itemRoute);
        }

        if (activeRoute?.includes('/matches/alerts')) {
          return possibleApplyButtonRoutes.includes(itemRoute);
        }

        if (
          activeRoute?.includes('/matches') ||
          activeRoute?.includes('/match/')
        ) {
          return itemRoute?.includes('/matches/filtering');
        }

        return false;
      }

      return !!(itemRoute && activeRoute.includes(itemRoute));
    },
    [activeRoute]
  );
};
