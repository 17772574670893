import { Form as FormikForm, type FormikFormProps } from 'formik';

type Props = Omit<FormikFormProps, 'placeholder'> & {
  placeholder?: string;
  onPointerEnterCapture?: () => void;
  onPointerLeaveCapture?: () => void;
};

export const Form = ({
  placeholder = undefined,
  onPointerEnterCapture = undefined,
  onPointerLeaveCapture = undefined,
  ...props
}: Props) => {
  return (
    <FormikForm
      onPointerEnterCapture={onPointerEnterCapture}
      onPointerLeaveCapture={onPointerLeaveCapture}
      placeholder={placeholder}
      {...props}
    />
  );
};
