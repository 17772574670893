import type { PropsWithChildren } from 'react';

type Props = {
  noMargin?: boolean;
  direction?: 'row' | 'column';
  gap?: number;
};

export function ModalActions({
  children,
  noMargin = false,
  direction = 'row',
  gap = 0,
}: PropsWithChildren<Props>) {
  return (
    <div className="ModalActions">
      {children}
      <style jsx>{`
        .ModalActions {
          display: flex;
          flex-direction: ${direction};
          gap: ${gap / 16}em;
          justify-content: space-evenly;
          margin: ${noMargin ? '0' : '0.625em 0'};
          width: 100%;
        }
      `}</style>
    </div>
  );
}
