import type { PropsWithChildren } from 'react';

import * as Colors from '@src/support/colors';
import { MOBILE_MAX_WIDTH } from '@src/constants';
import Link from 'next/link';

type Props = {
  href: string;
};

export const NavMainHeaderBarLogo = ({
  href,
  children,
}: PropsWithChildren<Props>) => (
  <div className="NavMainHeaderBarLogo">
    <Link href={href} className="NavLink">
      {children}
    </Link>
    <style jsx>
      {`
        .NavMainHeaderBarLogo :global(.NavLink) {
          -webkit-tap-highlight-color: transparent;
          display: flex;
        }

        .NavMainHeaderBarLogo {
          visibility: hidden;
          align-self: center;
          display: flex;
          justify-content: center;
          background-color: ${Colors.white};
          flex: 2 1;
          font-size: 1.125em;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
          .NavMainHeaderBarLogo {
            visibility: visible;
          }
        }
      `}
    </style>
  </div>
);
