import {
  FormControlLabel,
  type FormControlLabelProps,
  Radio,
  type RadioProps,
} from '@src/ui/material-ui';

interface OwnProps {
  value: string | boolean;
  castValue?: 'boolean';

  RadioProps?: Omit<RadioProps, 'color'>;
}

type Props = OwnProps & Omit<FormControlLabelProps, 'control' | 'value'>;

export function FormikRadioFieldOption({
  castValue,
  RadioProps,
  value,
  ...props
}: Props) {
  const stringValue = castValue === 'boolean' ? value.toString() : value;

  return (
    <FormControlLabel
      {...props}
      control={<Radio {...RadioProps} />}
      label={props.label}
      value={stringValue}
    />
  );
}
