import type { ReactNode } from 'react';
import clsx, { type ClassValue } from 'clsx';
import {
  IconButton,
  makeStyles,
  Snackbar as MuiSnackbar,
  type SnackbarContentProps,
  type SnackbarProps,
} from '@src/ui/material-ui';
import { FontAwesomeIcon } from '../font-awesome-icon';
import * as colors from '@src/support/colors';
import type { SnackbarMessageColor } from '@src/apollo/local';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';

const createIcon = (icon: IconProp, color: string) => {
  const IconComponent = (props: { className?: string }) => (
    <FontAwesomeIcon fixedWidth {...props} icon={icon} color={color} />
  );

  IconComponent.displayName = 'colorIcon';

  return IconComponent;
};

const colorIcon: Record<
  SnackbarMessageColor,
  (props: { className?: string }) => JSX.Element
> = {
  success: createIcon(['fas', 'check-circle'], colors.semanticSuccess),
  warning: createIcon(['fas', 'exclamation-triangle'], colors.semanticWarning),
  error: createIcon(['fas', 'exclamation-circle'], colors.semanticError),
  info: createIcon(['fas', 'info-circle'], colors.semanticInfo),
};

const useStyles = makeStyles(() => {
  const createSnackbarStyle = (backgroundColor: string) => ({
    '&.MuiSnackbarContent-root': {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.25,
      color: colors.black,
      backgroundColor: backgroundColor,
    },
  });

  return {
    success: createSnackbarStyle(colors.semanticSuccessLower),
    warning: createSnackbarStyle(colors.semanticWarningLower),
    error: createSnackbarStyle(colors.semanticErrorLower),
    info: createSnackbarStyle(colors.semanticInfoLower),
    icon: {
      fontSize: '1rem',
      marginRight: '0.5rem',
    },
    message: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    messageContent: {
      display: 'flex',
      alignItems: 'center',
    },
    closeIcon: {
      fontSize: '1.25rem',
      marginLeft: '0.25rem',
      color: colors.black,
    },
  };
});

interface OwnProps {
  message: ReactNode;
  onClose: () => void;
  color: SnackbarMessageColor;
  ContentProps?: Partial<SnackbarContentProps>;
  close: boolean;
  className?: ClassValue;
}

type Props = OwnProps & SnackbarProps;

export const Snackbar = ({
  message,
  onClose,
  color,
  close,
  ContentProps = {},
  ...props
}: Props) => {
  const classes = useStyles();
  const Icon = colorIcon[color];

  return (
    <MuiSnackbar
      message={
        <div className={classes.message}>
          <div className={classes.messageContent}>
            <Icon className={clsx(classes.icon)} />
            {message}
          </div>
          {close && (
            <IconButton
              key="close"
              aria-label="Close"
              onClick={onClose}
              style={{ padding: '0' }}
            >
              <FontAwesomeIcon
                fixedWidth
                className={classes.closeIcon}
                icon={['fal', 'times']}
              />
            </IconButton>
          )}
        </div>
      }
      {...props}
      onClose={onClose}
      ContentProps={{
        ...ContentProps,
        classes: {
          ...ContentProps.classes,
          root: clsx(classes[color], ContentProps?.classes?.root),
          message: clsx(classes.message, ContentProps?.classes?.message),
        },
      }}
    />
  );
};
