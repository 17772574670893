import type { CSSProperties, PropsWithChildren } from 'react';

type Props = {
  style?: CSSProperties;
};

export const NavMainHeader = ({
  children,
  style = {},
}: PropsWithChildren<Props>) => (
  <div className="NavMainHeader" style={style}>
    {children}
    <style jsx>{`
      .NavMainHeader {
        display: flex;
        flex-direction: column;
        position: sticky;
        left: 0;
        right: 0;
        top: 0;
        z-index: 30;
      }
    `}</style>
  </div>
);
