import { type PropsWithChildren } from 'react';

import * as Colors from '@src/support/colors';
import { rgba } from '@src/support/rgba';
import { MOBILE_MAX_WIDTH } from '@src/constants';

type Props = PropsWithChildren<{
  withinPanel?: boolean;
}>;

export const NavMainHeaderTabs = ({ children, withinPanel = false }: Props) => (
  <div className="NavMainHeaderTabs">
    {children}
    <style jsx>
      {`
        .NavMainHeaderTabs {
          -webkit-overflow-scrolling: touch;
          background-color: ${Colors.white};
          overflow-x: auto;
          text-align: center;
          display: flex;
          justify-content: center;
          flex-direction: row;
          align-items: normal;
          box-shadow: ${rgba(Colors.black, 0.05)} 0 0.125rem 0 0;
        }

        @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
          .NavMainHeaderTabs {
            ${withinPanel ? '' : 'width: 100vw;'}
            align-items: normal;
            flex-direction: column;
            align-content: space-between;
          }
      `}
    </style>
  </div>
);
