import {
  MOBILE_MAX_WIDTH,
  DESKTOP_MIN_WIDTH,
} from '@src/constants/breakpoints';
import { useMedia } from '@src/hooks/use-media';

const queries = [
  `(max-width: ${MOBILE_MAX_WIDTH})`,
  `(min-width: ${DESKTOP_MIN_WIDTH})`,
];
const values = [true, false];

export function useIsMobile(defaultValue = false) {
  return useMedia(queries, values, defaultValue);
}
