import { createContext } from 'react';

export type PanelState = {
  open: boolean;
  topic?: string;
  subTopic?: string;
};

// Exported for mocks
export const ChatContext = createContext<{
  unreadCount: number;
  open: (options?: Omit<PanelState, 'open'>) => void;
  close: () => void;
} | null>(null);
