import { FormikTextField } from '@src/components/formik';
import { analytics } from '@src/lib/analytics';
import * as colors from '@src/support/colors';
import { Button } from '@src/ui';
import { SEGMENT_EVENTS } from '@src/constants';

import { HelpcenterCard } from './helpcenter-card';

type Props = {
  topic: string;
  subtopic: string;
  handleSubmit: () => void;
};
export function ChatPrompt({ topic, subtopic, handleSubmit }: Props) {
  return (
    <HelpcenterCard title="Still need help? Send us a message">
      <div className="topicContainer">
        <p className="topicHeader">Topic:</p>
        <p className="topicSubject">{topic}</p>
      </div>
      <div className="subtopicContainer">
        <p className="topicHeader">Subtopic:</p>
        <p className="topicSubject">{subtopic}</p>
      </div>
      <div className="formContainer">
        <FormikTextField
          fullWidth
          name="question"
          required
          multiline
          minRows={10}
          maxRows={10}
          placeholder="Please enter message here"
          variant="outlined"
        />
      </div>
      <div className="innerLowerContentWrapper">
        <div className="innerLowerTextWrapper">
          <p className="innerLowerText">
            Please don&apos;t provide sensitive personal information (e.g.
            credit card numbers).
          </p>
        </div>
        <div className="buttonWrapper">
          <Button
            color="primary"
            type="button"
            shape="rounded"
            style={{ width: '100%', marginTop: 8 }}
            onClick={() => {
              analytics.track(SEGMENT_EVENTS.CHAT_PANEL.SUBMIT_QUESTION, {
                source: 'Question Page Web',
                topic: topic,
                subtopic: subtopic,
              });
              handleSubmit();
            }}
          >
            Send us a Message
          </Button>
        </div>
      </div>
      <style jsx>{`
        .formContainer {
          background-color: ${colors.white};
          border-radius: 0.25rem;
        }
        .innerLowerText {
          font-size: 0.875rem;
        }
        .innerLowerTextWrapper {
          margin-top: 0.75rem;
        }
        .subtopicContainer {
          margin-bottom: 1rem;
          display: flex;
          flex-direction: row;
        }
        .topicContainer {
          margin-bottom: 0.5rem;
          padding-top: 0.5rem;
          display: flex;
          flex-direction: row;
        }
        .topicHeader {
          font-weight: bold;
          font-size: 1rem;
          padding-right: 0.25rem;
        }
      `}</style>
    </HelpcenterCard>
  );
}
