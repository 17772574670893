import { analytics } from '@src/lib/analytics';

/**
 *
 * @param {string} event Event name to be fired
 * @param {boolean} isMobile Flag to identify if user is in mobile device
 * @param {string} referrer Pathname for the page where the user was when clicked
 * @param {object} extras Object with extra fields to be passed on to the analytics tool
 */

export function trackNavigationClick(
  event: string,
  isMobile: boolean,
  referrer: string = '',
  extras: object = {}
) {
  const source = referrer.split('/').filter(Boolean)[0];

  analytics.track(event, {
    referrer: referrer,
    source: source,
    device_type: isMobile ? 'mobile' : 'web',
    ...extras,
  });
}
