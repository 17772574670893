export type { RequireOneOf } from './require-one-of';

export type UserFrom<T> = Extract<T, { readonly __typename?: 'User' }>;

enum SortDirection {
  Ascending = 'asc',
  Descending = 'desc',
}

// https://stackoverflow.com/a/64966647/75377
export type SortDirectionOption = `${SortDirection}`;

/**
 * Used to filter out `null | undefined` from array value types. Should
 * be avoided in favor of updating the nullability of the elements in
 * the API itself, but for cases where that's not practical we can use this.
 * @example
 * type File = {
 *   id: string;
 * };
 * type SomeThing = {
 *   files: (File | undefined | null)[];
 * }
 * const test: SomeThing = { files: [{ id: '1' }] };
 * // ERROR: Object is possibly null or undefined
 * console.log(test.files[0].id);
 * // Fix:
 * const safeFiles = test.files.filter(nonNullValues);
 * console.log(safeFiles[0].id);
 */
export function nonNullValues<T>(el: T): el is NonNullable<T> {
  return typeof el !== 'undefined' && el !== null;
}
