import type { PropsWithChildren } from 'react';

export function ScrollView(props: Required<PropsWithChildren<{}>>) {
  return (
    <div className="ScrollView">
      {props.children}
      <style jsx>{`
        .ScrollView {
          -webkit-overflow-scrolling: touch;
          bottom: 0;
          display: flex;
          flex-direction: column;
          height: 100%;
          left: 0;
          overflow-x: hidden;
          overflow-y: auto;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
        }
      `}</style>
    </div>
  );
}
